import React from "react";
import "./skeletonLuka.scss";

const Skeleton = () => {
  return (
    <>
      <div id="luka-sk-template-skeleton">
        <div className="luka-sk-metodos">
          <div>
            <span className="luka-sk-anim-h luka-sk-bg-grey"></span>
          </div>
          <div>
            <span className="luka-sk-anim-h luka-sk-bg-grey"></span>
          </div>
          <div>
            <span className="luka-sk-anim-h luka-sk-bg-grey"></span>
          </div>
        </div>
        <div className="content-inputs">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-6">
                  <div className="luka-sk-text luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
                <div className="col-12">
                  <div className="luka-sk-inp luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-6">
                  <div className="luka-sk-text luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
                <div className="col-12">
                  <div className="luka-sk-inp luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="luka-sk-text luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
                <div className="col-12">
                  <div className="luka-sk-inp luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="luka-sk-text luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
                <div className="col-12">
                  <div className="luka-sk-inp luka-sk-bg-grey luka-sk-anim-h"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skeleton;
