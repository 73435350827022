import { SET_ORGANIZATION_DONATION } from './types';

export const setOrganization = (item) => {
  return {
    type: SET_ORGANIZATION_DONATION,
    payload: {
      selectedOrganization: item,
    },
  };
};
