import React, { useState } from "react";
import "../../../assets/scss/components/modalSelect.scss";
import { currencyFormatDE, currencyFormatValue } from "../../../utils/globals";

const SelectModal = ({ entradas, onSelect, Iso,Simbolo,global }) => {
  const [EntradaSel, setEntradaSel] = useState(null);
  const handleClick = (ent) => {
    if (typeof onSelect === "function") onSelect(ent);
    setEntradaSel(ent);
  };
  return (
    <div className="modalEntradas">
      <h1>Por favor seleccione la categoría</h1>
      <div className="entradas">
        {entradas.map((ent, index) => (
          <div
            key={ent.TipoEntradaId}
            className="entrada"
            onClick={() => {
              handleClick(ent);
            }}
          >
            <p>
              {ent.TipoEntradaNombre}
              <b style={{marginLeft:"20px"}} >
                {Iso === "VES"
                  ? global.MonedaPorDefecto === "VES"
                    ? `${Simbolo}
                        ${currencyFormatDE(
                          ent.Precio,
                          global.thousandsSeparator,
                          global.decimalSeparator,
                          global.MostrarDecimales
                        )}`
                    : `$
                      ${currencyFormatDE(
                        currencyFormatValue(
                          global.dolarToday,
                          ent.Precio,
                          global.MostrarDecimales
                        ),
                        global.thousandsSeparator,
                        global.decimalSeparator,
                        global.MostrarDecimales
                      )}`
                  : `${Simbolo}
                      ${currencyFormatDE(
                        ent.Precio,
                        global.thousandsSeparator,
                        global.decimalSeparator,
                        global.MostrarDecimales
                      )}`}
              </b>
            </p>
            <span
              className={
                EntradaSel && EntradaSel.TipoEntradaId === ent.TipoEntradaId
                  ? "active"
                  : ""
              }
            ></span>
          </div>
        ))}
        {/* <button className="btn-ppal btn-skin active">Aceptar</button> */}
      </div>
    </div>
  );
};

export default SelectModal;
