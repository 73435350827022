export const GET_GLOBAL_VAR = "global/GET_GLOBAL_VAR";
export const SET_LOADING = "global/SET_LOADING";
export const GET_GLOBAL_CONFIG_START = "GET_GLOBAL_CONFIG_START";
export const GET_GLOBAL_CONFIG_SUCCESS = "GET_GLOBAL_CONFIG_SUCCESS";
export const GET_GLOBAL_CONFIG_ERROR = "GET_GLOBAL_CONFIG_ERROR";
export const SET_DOLAR_VALUE = "SET_DOLAR_VALUE";
export const SET_DOLAR_UPDATE = "SET_DOLAR_UPDATE";
export const SET_IDIOMA_VALUE = "SET_IDIOMA_VALUE";
export const SET_CURRENCY_VALUE = "SET_CURRENCY_VALUE";
export const SET_LOGIN = "SET_LOGIN";
