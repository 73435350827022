import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import ButtonPlus from "../car/buttonPlus";
// import ButtonMinus from "../car/buttonMinus";
import { useTranslation } from "react-i18next";
import ImgPlaceholder from "../../assets/images/placeholderImg.svg";
import ButtonAdd from "../car/buttonAdd";
import {
  ADD_PRODUCTO,
  OPEN_MODAL,
  REMOVE_ITEM,
  UPDATE_CAR,
} from "../../../../../reducers/carReducer";
import { getProductoCar } from "../../services/car";
import { formatMonto } from "../utils";

const ModalProd = ({ producto, closeModal }) => {
  const { t } = useTranslation();
  const carStore = useSelector((store) => store.car);
  const { MonedaPorDefecto } = useSelector((store) => store.global);
  // const shop = useSelector((store) => store.shop);
  const inCar = carStore.productos.find((n) => n.id === producto.id);
  const [Variantes, setVariantes] = useState(inCar ? inCar.variantes : []);
  const [ErrorVariantes, setErrorVariantes] = useState(false);
  const [totalVariantes, setTotalVariantes] = useState(0);
  const MonedaTienda = useSelector((state) =>
    state.event.selectedFunction.Iso === "VES"
      ? state.global.MonedaPorDefecto
      : state.event.selectedFunction.Iso
  );

  const [imgActive, setImgActive] = useState(
    producto.imgs[0] || ImgPlaceholder
  );
  const [cantidad, setCantidad] = useState(inCar ? inCar.cantidad : 0);
  const dispatch = useDispatch();
  // console.log(producto);
  useEffect(() => {
    console.log(Variantes);
    let _tV = 0;
    Variantes.map((v) => {
        _tV = parseFloat(_tV) + parseFloat(v.value.price_e2);
    });
    setTotalVariantes(_tV);
  }, [Variantes]);
  useEffect(() => {
    // console.log("carStore", carStore);
    if (!inCar) {
      setCantidad(1);
    } else {
      setVariantes(inCar.variantes);
    }
  }, [inCar]);

  const addToCar = async () => {
    if (inCar) {
      if (cantidad > 0) {
        dispatch({
          type: UPDATE_CAR,
          payload: carStore.productos.map((p) => {
            let mod = { ...p };
            if (mod.id === inCar.id) {
              mod.cantidad = cantidad || 1;
            }
            return mod;
          }),
        });
      } else {
        dispatch({ type: REMOVE_ITEM, payload: inCar.id });
      }
      // dispatch(openModal({ producto: null }));
    } else {
      if (
        producto.properties.length > 0 &&
        Variantes.length !== producto.properties.length
      ) {
        setErrorVariantes(true);
        return;
      }
      const prodDataExtra = await getProductoCar(producto, MonedaTienda);
      dispatch({
        type: ADD_PRODUCTO,
        payload: {
          car: carStore.productos,
          producto: { ...producto, ...prodDataExtra },
          variantes: Variantes,
          cantidad: cantidad || 1,
        },
      });
      dispatch({ type: OPEN_MODAL, payload: { producto: null } });
      // dispatch({
      //     type: SHOP_ACTIONTYPES.TOGGLE_MODAL,
      //     payload: { producto: null }
      // });
    }
  };

  const changeVariante = (varianteId, opcionId) => {
    setErrorVariantes(false);
    let nV = { ...Variantes, ["op_" + varianteId]: opcionId };
    if (inCar) {
      dispatch({
        type: UPDATE_CAR,
        payload: carStore.productos.map((p) => {
          let mod = { ...p };
          if (mod.id === inCar.id) {
            mod.variantes = nV;
          }
          return mod;
        }),
      });
    }
    setVariantes(nV);
  };
  const handleMinus = () => {
    const tope = inCar ? 1 : 2;
    if (cantidad >= tope) {
      setCantidad(cantidad - 1);
    }
  };
  const handlePlus = () => {
    setCantidad(cantidad + 1);
  };

  return (
    <div className="modalProd d-flex" tabIndex="-1" role="dialog">
      <div className="wrap">
        <div className="content">
          <div className="d-flex flex-column flex-md-row align-items-stretch">
            <i
              className="icon-cerrar"
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL,
                  payload: { producto: null },
                })
              }
            ></i>
            <div className="image w-100">
              <figure className="mb-0 h-100 ppal">
                <img src={imgActive} alt="" />
              </figure>
              {producto.imgs.length > 1 && (
                <div className="thumbnails">
                  {producto.imgs.map((img) => (
                    <figure key={img} onClick={() => setImgActive(img)}>
                      <img src={img} alt={img} key={img} />
                    </figure>
                  ))}
                </div>
              )}
            </div>
            <div className="w-100 detalle">
              <div className="header">
                <h2 className="textTitle">{producto.nombre}</h2>
                <p className="textRegular">{producto.descripcion}</p>
              </div>
              {/* OPCIONES CUSTOMS */}
              <div className="opciones">
                {producto.branch_good_properties.map((v) => {
                  return (
                    <div key={v.id} className="customOption">
                      <div className="top d-flex justify-content-between">
                        <div>
                          <h3 className={`textSubTitle`}>{v.display_name}</h3>
                          <h4 className="textSubTitle">
                            {v.descripcion || ""}
                          </h4>
                        </div>
                        <span>{v.obligatorio ? "Obligatorio" : ""}</span>
                      </div>
                      <div className="bodyOptions">
                        {v.property_options.map((op, index) => {
                          console.log(op);
                          return (
                            <div
                              className="d-flex justify-content-between option"
                              key={op.id}
                            >
                              <>
                                <div className={`radioCustomWrap`}>
                                  <input
                                    type="radio"
                                    name={`op_${v.id}`}
                                    id={op.id}
                                    value={op.id}
                                    onClick={() => {
                                      let existV = Variantes.findIndex(
                                        (va) =>
                                          va.op.property_id === v.property_id
                                      );
                                      if (existV >= 0) {
                                        setVariantes(
                                          [...Variantes].map((vaN, idx) =>
                                            idx === existV
                                              ? { op: v, value: op }
                                              : vaN
                                          )
                                        );
                                      } else {
                                        setVariantes([
                                          ...Variantes,
                                          { op: v, value: op },
                                        ]);
                                      }
                                    }}
                                  />
                                  <label htmlFor={op.id} key={op.id}>
                                    {op.name}
                                  </label>
                                </div>
                                {op.display_price ? (
                                  <span className="precio">
                                    {/* + {producto.symbol_currency} {v.price_e2} */}
                                    + {op.display_price}
                                  </span>
                                ) : null}
                              </>
                            </div>
                          );
                          //   return (
                          //     <div className="options-variants" key={v.id}>
                          //       <h4 className="name">{v.display_name}</h4>
                          //       {v.property_options.map((op: any) => (
                          //         <label htmlFor={op.id} key={op.id}>
                          //           <input
                          //             type="radio"
                          //             name={`op_${v.id}`}
                          //             id={op.id}
                          //             value={op.id}
                          //             onClick={() => {
                          //               let existV = Variantes.findIndex(
                          //                 (va) =>
                          //                   va.op.property_id === v.property_id
                          //               );
                          //               if (existV >= 0) {
                          //                 setVariantes(
                          //                   [...Variantes].map((vaN, idx) =>
                          //                     idx === existV
                          //                       ? { op: v, value: op }
                          //                       : vaN
                          //                   )
                          //                 );
                          //               } else {
                          //                 setVariantes([
                          //                   ...Variantes,
                          //                   { op: v, value: op },
                          //                 ]);
                          //               }
                          //             }}
                          //           />
                          //           <p>{op.name}</p>
                          //           <b>{op.display_price}</b>
                          //         </label>
                          //       ))}
                          //     </div>
                          //   );
                        })}
                      </div>
                      {!Variantes["op_" + v.property_id] && ErrorVariantes && (
                        <span className="errorVar">
                          Debe seleccionar {v.display_name}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* OPCIONES CUSTOMS */}
              <div className="footer">
                <>
                  <div className="buttonsModal d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      onClick={handleMinus}
                    >
                      <path d="M4,5.5 L4,19.5 C4,20.3284271 4.67157288,21 5.5,21 L19.5,21 C20.3284271,21 21,20.3284271 21,19.5 L21,5.5 C21,4.67157288 20.3284271,4 19.5,4 L5.5,4 C4.67157288,4 4,4.67157288 4,5.5 Z M3,5.5 C3,4.11928813 4.11928813,3 5.5,3 L19.5,3 C20.8807119,3 22,4.11928813 22,5.5 L22,19.5 C22,20.8807119 20.8807119,22 19.5,22 L5.5,22 C4.11928813,22 3,20.8807119 3,19.5 L3,5.5 Z M8.5,13 C8.22385763,13 8,12.7761424 8,12.5 C8,12.2238576 8.22385763,12 8.5,12 L16.5,12 C16.7761424,12 17,12.2238576 17,12.5 C17,12.7761424 16.7761424,13 16.5,13 L8.5,13 Z" />
                    </svg>
                    <span>{cantidad}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      onClick={handlePlus}
                    >
                      <path d="M13,12 L16.5,12 C16.7761424,12 17,12.2238576 17,12.5 C17,12.7761424 16.7761424,13 16.5,13 L13,13 L13,16.5 C13,16.7761424 12.7761424,17 12.5,17 C12.2238576,17 12,16.7761424 12,16.5 L12,13 L8.5,13 C8.22385763,13 8,12.7761424 8,12.5 C8,12.2238576 8.22385763,12 8.5,12 L12,12 L12,8.5 C12,8.22385763 12.2238576,8 12.5,8 C12.7761424,8 13,8.22385763 13,8.5 L13,12 Z M3,5.5 C3,4.11928813 4.11928813,3 5.5,3 L19.5,3 C20.8807119,3 22,4.11928813 22,5.5 L22,19.5 C22,20.8807119 20.8807119,22 19.5,22 L5.5,22 C4.11928813,22 3,20.8807119 3,19.5 L3,5.5 Z M4,5.5 L4,19.5 C4,20.3284271 4.67157288,21 5.5,21 L19.5,21 C20.3284271,21 21,20.3284271 21,19.5 L21,5.5 C21,4.67157288 20.3284271,4 19.5,4 L5.5,4 C4.67157288,4 4,4.67157288 4,5.5 Z" />
                    </svg>
                  </div>
                  {((inCar && cantidad !== inCar.cantidad) || !inCar) && (
                    <ButtonAdd
                      label={
                        inCar
                          ? cantidad === 0
                            ? t("Eliminar")
                            : t("Modificar")
                          : t("Agregar")
                      }
                      inCar={inCar}
                      onClick={addToCar}
                      // disabled={
                      //     producto.properties.length >
                      //         0 &&
                      //     Object.keys(Variantes)
                      //         .length === 0
                      // }
                    />
                  )}
                </>
                <span className="monto">
                  {producto.symbol_currency}{" "}
                  {inCar
                    ? formatMonto(
                        (producto.precio + totalVariantes * 100) *
                          inCar.cantidad,
                        2,
                        ",",
                        "."
                      )
                    : formatMonto(
                        producto.precio + totalVariantes * 100,
                        2,
                        ",",
                        "."
                      )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalProd;
