import {
  GET_GLOBAL_CONFIG_START,
  GET_GLOBAL_CONFIG_SUCCESS,
  GET_GLOBAL_CONFIG_ERROR,
  SET_DOLAR_VALUE,
  SET_DOLAR_UPDATE,
  SET_IDIOMA_VALUE,
  SET_CURRENCY_VALUE,
  SET_LOGIN,
} from "./types";

export const setGlobalConfig = (config) => ({
  type: GET_GLOBAL_CONFIG_SUCCESS,
  payload: config,
});

export const startGetConfig = () => ({
  type: GET_GLOBAL_CONFIG_START,
  payload: true,
});

export const errorGetConfig = (error) => ({
  type: GET_GLOBAL_CONFIG_ERROR,
  payload: error,
});

export const setDolarToday = (value) => ({
  type: SET_DOLAR_VALUE,
  payload: value,
});

export const updateDolarToday = (value) => ({
  type: SET_DOLAR_UPDATE,
  payload: value,
});

export const setIdioma = (value) => ({
  type: SET_IDIOMA_VALUE,
  payload: value,
});

export const changeCurrency = (value) => ({
  type: SET_CURRENCY_VALUE,
  payload: value,
});
export const setLogin = (value) => ({
  type: SET_LOGIN,
  payload: value,
});