import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import moment from 'moment';
import CalendarItem from './../CalendarItem';

let eventFunctions = {};

class CalendarPicker extends React.Component {
  state = { dayFunctions: undefined };

  componentDidMount() {
    const {
      functions,
      hourFormat,
      onClickItem,
      onClickCollapsablePanel,
      onClickHideSelectedFunction,
      languageDays,
    } = this.props;
    eventFunctions = {};
    functions &&
      functions.forEach((func) => {
        if (
          !eventFunctions.hasOwnProperty(
            moment(func.Fecha).format('DD/MM/YYYY')
          )
        ) {
          func.isClickleable = false;
          eventFunctions[moment(func.Fecha).format('DD/MM/YYYY')] = {
            functions: [func],
          };
          let funcClickleable = { ...func };
          funcClickleable.isClickleable = true;
          eventFunctions[
            moment(func.Fecha).format('DD/MM/YYYY')
          ].functions.push(funcClickleable);
        } else {
          func.isClickleable = true;
          eventFunctions[
            moment(func.Fecha).format('DD/MM/YYYY')
          ].functions.push(func);
        }
      });

    $('.wrap-opciones.datepicker').datepicker({
      dayNamesMin: languageDays,
      numberOfMonths: $(window).width() > 769 ? 2 : 1,
      defaultDate: moment(functions[0].Fecha).format('DD/MM/YYYY'),
      beforeShowDay: function (date) {
        let highlight = eventFunctions[moment(date).format('DD/MM/YYYY')];
        if (highlight) {
          if (highlight.functions.length > 1) {
            return [
              true,
              'event multiple',
              highlight.functions[0].CiudadNombre.toUpperCase() +
                ' - ' +
                moment(highlight.functions[0].Fecha).format('dddd DD MMMM') +
                ' - Multiples Funciones',
            ];
          }
          return [
            true,
            'event',
            highlight.functions[0].CiudadNombre.toUpperCase() +
              ' - ' +
              moment
                .utc(highlight.functions[0].Fecha)
                .format(
                  'dddd DD MMMM, ' + (hourFormat === '12hr' ? 'hh:mm' : 'HH:mm')
                ),
          ];
        } else {
          return [false, '', ''];
        }
      },
      onSelect: function (date) {
        let selectedDay = eventFunctions[date]
          ? eventFunctions[date]
          : eventFunctions[moment(date).format('DD/MM/YYYY')];
        if (selectedDay.functions.length > 2) {
          $(
            '.ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multi.ui-datepicker-multi-2'
          ).css({ display: 'none' });
          $(
            '.ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all'
          ).css({ display: 'none' });
          setDayFunctions(selectedDay.functions);
          onClickHideSelectedFunction(true);
        } else {
          let func = selectedDay.functions[0];
          func.funcNameToUrl = func.EventoNombre.replace(/\s/g, '-')
            .normalize('NFD')
            .replace(/[#?+&'\u0300-\u036f]/g, '')
            .toLowerCase();
          onClickItem(func);
          onClickCollapsablePanel();
          window.history.pushState(
            null,
            null,
            `${func.EventoId}?selectedFunctionId=${func.FuncionId}`
          );
        }
      },
    });
    const setDayFunctions = (functions) => {
      this.setState({ dayFunctions: functions });
    };
    window.addEventListener('resize', function () {
      $('.wrap-opciones.datepicker').datepicker(
        'option',
        'numberOfMonths',
        $(window).width() > 769 ? 2 : 1
      );
    });
  }

  render() {
    const {
      hourFormat,
      hourUnit,
      onClickItem,
      onClickCollapsablePanel,
      onClickHideSelectedFunction,
      t,
    } = this.props;
    const { dayFunctions } = this.state;
    return (
      <div className={`wrap-opciones ${dayFunctions ? '' : 'datepicker'}`}>
        {dayFunctions &&
          dayFunctions.map((func, index) => {
            const funcNameToUrl = func.EventoNombre.replace(/\s/g, '-')
              .normalize('NFD')
              .replace(/[#?+&'\u0300-\u036f]/g, '')
              .toLowerCase();
            func = { ...func, funcNameToUrl: funcNameToUrl };
            return (
              <CalendarItem
                key={index}
                item={func}
                hourFormat={hourFormat}
                hourUnit={hourUnit}
                isMultiCity={false}
                isMultiHour={dayFunctions.length > 0}
                isCalendarListItemView={true}
                onClickItem={async () => {
                  onClickItem(func);
                  onClickCollapsablePanel();
                  onClickHideSelectedFunction(false);
                  window.history.pushState(
                    null,
                    null,
                    `${func.EventoID}?selectedFunctionId=${func.FuncionId}`
                  );
                }}
              />
            );
          })}
        {dayFunctions && (
          <button
            className="ver-calendario color-ppal"
            onClick={() => {
              this.setState({ dayFunctions: undefined });
              onClickHideSelectedFunction(false);
              $(
                '.ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multi.ui-datepicker-multi-2'
              ).css({ display: 'block' });
            }}
          >
            {t('returnToCalendar')}
          </button>
        )}
      </div>
    );
  }
}

CalendarPicker.propTypes = {
  functions: PropTypes.array.isRequired,
};

export default withTranslation()(CalendarPicker);
