import { connect } from 'react-redux';
import Confirmation from './Confirmation';

const mapStateToProps = (state) => ({
  global: state.global,
});

// const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps //,mapDispatchToProps
)(Confirmation);
