export const changeForm = (e, form) => {
    let { name, value } = e.target;
    let _f = { ...form };
    if (_f[name].type === 'numeric') {
        if (!/[0-9]$/.test(value) && value !== '') {
            return _f;
        }
    }
    if (_f[name].type === 'decimal') {
        let exp =
            _f[name].separator === '.'
                ? /^([0-9]+.?[0-9]{0,2})$/
                : /^([0-9]+,?[0-9]{0,2})$/;
        if (!exp.test(value) && value !== '') {
            return _f;
        }
    }
    if (_f[name].max && _f[name].max === value.length) {
        return _f;
    }
    if (
        _f[name].required &&
        ((typeof _f[name].value === 'string' && _f[name].value !== '') ||
            _f[name].value !== null)
    ) {
        _f = {
            ..._f,
            [name]: {
                ..._f[name],
                error: false,
                errorMensaje: ''
            }
        };
    }

    _f = {
        ..._f,
        [name]: {
            ..._f[name],
            value
        }
    };
    return _f;
};

export const validarForm = (form) => {
    let invalid = false;
    let _F = { ...form };
    for (let key of Object.keys(_F)) {
        // console.log((typeof _F[key].value === 'string') && _F[key].value.trim()==="" || _F[key].value===null);
        if (key !== 'invalid') {
            _F[key].error = false;
            _F[key].errorMensaje = '';

            if (_F[key].min && _F[key].value.trim().length < _F[key].min) {
                _F[key].error = true;
                _F[
                    key
                ].errorMensaje = `Mínimo de caractreres es de (${_F[key].min})`;
                invalid = true;
            }

            if (
                ((typeof _F[key].value === 'string' &&
                    _F[key].value.trim() === '') ||
                    _F[key].value === null) &&
                _F[key].required
            ) {
                _F[key].error = true;
                _F[key].errorMensaje = 'El campo es requerido';
                invalid = true;
            }

            if (
                _F[key].type === 'email' &&
                _F[key].value.trim() !== '' &&
                !/^([\da-z_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/.test(
                    _F[key].value
                )
            ) {
                _F[key].error = true;
                _F[key].errorMensaje = 'Formato de correo no válido';
                invalid = true;
            }
        }
    }
    return { ..._F, invalid };
};

// inputs individuales
export const validarInput = (input) => {
    // let invalid = false;
    let _I = { ...input };
    // console.log((typeof _I[key].value === 'string') && _I[key].value.trim()==="" || _I[key].value===null);
    _I.error = false;
    _I.errorMensaje = '';

    if (_I.min && _I.value.trim().length < _I.min) {
        _I.error = true;
        _I.errorMensaje = `Mínimo de caractreres es de (${_I.min})`;
        // invalid = true;
    }

    if (
        ((typeof _I.value === 'string' && _I.value.trim() === '') ||
            _I.value === null) &&
        _I.required
    ) {
        _I.error = true;
        _I.errorMensaje = 'El campo es requerido';
        // invalid = true;
    }

    if (
        _I.type === 'email' &&
        _I.value.trim() !== '' &&
        !/^([\da-z_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/.test(_I.value)
    ) {
        _I.error = true;
        _I.errorMensaje = 'Formato de correo no válido';
        // invalid = true;
    }
    return _I;
};

export const changeInput = (e, dato) => {
    let input = { ...dato };
    let value = e.target ? e.target.value : e;
    if (input.type === 'numeric') {
        if (!/[0-9]$/.test(value) && value !== '') {
            return input;
        }
    }
    if (input.type === 'decimal') {
        let exp =
            input.separator === '.'
                ? /^([0-9]+.?[0-9]{0,2})$/
                : /^([0-9]+,?[0-9]{0,2})$/;
        if (!exp.test(value) && value !== '') {
            return input;
        }
    }
    if (input.max && input.max + 1 === value.length) {
        return input;
    }

    input = {
        ...dato,
        value
    };
    return input;
};

export const formatMonto = (m, n, s, c) => {
    m = m.toString();
    let dec = m.substring(m.length - 2, m.length);
    let int = m.substring(0, m.length - 2);
    m = int + c + dec;

    m = parseFloat(m);
    let re = '\\d(?=(\\d{' + 3 + '})+' + (n > 0 ? '\\D' : '$') + ')'
    let num = m.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(
        new RegExp(re, 'g'),
        '$&' + (s ? s : '')
    );
};
export const formatMontoR = (m, n, s, c) => {
    m = parseFloat(m);
    let re = '\\d(?=(\\d{' + 3 + '})+' + (n > 0 ? '\\D' : '$') + ')'
    let num = m.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(
        new RegExp(re, 'g'),
        '$&' + (s ? s : '')
    );
};

export const mapStatus = (status) => {
    if (status.order_is_confirmed) {
        return 'Iniciado';
    }
    if (status.provider_started) {
        return 'Despachado';
    }
    if (status.order_arrived) {
        return 'Entregado';
    }
    return 'Desconocido';
};
