import React, { useEffect,useRef } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";
import {
    ButtonLogin
} from "@TicketMundo/login-tm-library";
const Header = (props) => {
    const { global, confirmation, customer, t, getIdioma, translate, email,customStyle,backBtn } = props;
    const button = useRef(null);
    //const { refreshToken, loginByToken } = useLoginEvents();

    const cambiarIdioma = () => {
        let idioma = global.Idioma.toLowerCase() === "es" ? "en" : "es";
        getIdioma(idioma);
    };
    const buttonClick = () => {
        button.current.classList.toggle("active");
    };
    const buttonBlur = () => {
        setTimeout(() => {
            button.current.classList.remove("active");
        }, 300);
    };
   /* useEffect(() => {
        if(window.location.search.split("?token=")[1]){
            loginByToken(window.location.search.split("?token=")[1]);
        }
    }, []);*/
   
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    return !confirmation ? (
        global.header !== "none" ? (
            <div className={`header-ev ${ customStyle ? customStyle : `` }`}>
                <div className="container-custom-tm" style={{ height: "100%" }}>
                    <div className="wrap-header container-header-home">
                        {backBtn}
                        {
                            !customStyle ? <div>
                                {" "}
                                {global.header === "show" ? (
                                    <a href={global.inicio ? global.inicio : "#"}>
                                        <figure className="logo-home">
                                            <img src={global.logo} alt="" />
                                        </figure>
                                    </a>
                                ) : (
                                    <Skeleton height={60} width={90} />
                                )}
                            </div>
                            :<div></div>
                        }
                        {translate && (
                            <div id="lang">
                                <button
                                    onClick={buttonClick}
                                    onBlur={buttonBlur}
                                    ref={button}
                                >
                                    <span className="color-texto-contenido">
                                        {global.Idioma.toLowerCase() === "es"
                                            ? "Español"
                                            : "English"}
                                    </span>
                                    <i className="icon-idioma"></i>
                                </button>
                                <section className="options">
                                    <a
                                        onClick={cambiarIdioma}
                                        className={
                                            global.Idioma.toLowerCase() ===
                                                "es" && "active"
                                        }
                                    >
                                        Español
                                    </a>
                                    <a
                                        onClick={cambiarIdioma}
                                        className={
                                            global.Idioma.toLowerCase() ===
                                                "en" && "active"
                                        }
                                    >
                                        Ingles
                                    </a>
                                </section>
                            </div>
                        )}
                        {
                            global?.LoginActive === true ? <ButtonLogin /> :null
                        }
                    </div>
                </div>
            </div>
        ) : <div className={`header-ev ${ customStyle ? customStyle : `` }`}></div>
    ) : (
        <div className="header header-confirm">
            <div className="header-ev title-h">
                <div style={{minHeight:'30px'}}>
                    {global.header === "show" && (
                        <a href={global.inicio ? global.inicio : "#"}>
                            <figure className="logo-home logo-conf-top">
                                <img src={global.logo} alt="" />
                            </figure>
                        </a>
                    )}
                </div>
                <div>
                    <img src="https://cdn.ticketmundo.live/commons/exito-tm.gif" width="56" alt="success" style={{margin:`12px 0px`}}/>
                </div>
                <h1 className="clear-fl">
                    <span className="showA">Listo {` ${customer}!`}</span>
                    <span className="showA"> Ya tienes confirmada tu orden</span>
                </h1>
                <p style={{fontSize:`15px`}}>{t('confirmationDetails')}</p>
                <p><b>{email}</b></p>
            </div>
        </div>
    );
};

Header.propTypes = {
    global: PropTypes.object,
};

const IconCheck = ({color="var(--c1)"}) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="32" cy="32" r="30" fill="white" stroke={color} strokeWidth="4"/>
  <path d="M16.7383 31.7339L28.05 43.4619L47.4041 23.3955" fill="white"/>
  <path d="M16.7383 31.7339L28.05 43.4619L47.4041 23.3955" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default withTranslation()(Header);
