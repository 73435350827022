import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Services from '../../../services/Services';
import Transbank from '../../Tickets/Purchase/PayMethods/Transbank';
import PayU from '../../Tickets/Purchase/PayMethods/PayU';
import { URL_BASE } from '../../../utils/globals';
import SkeletonLuka from '../../../components/Loader/skeletonLuka'

let client = {};
const PayMethods = (props) => {
  const { global, t, selectedOption, detalleRegistro,moneda } = props;
  const [banks, setBanks] = useState([]);
  const [tokenLuka, setTokenLuka] = useState('');
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [typePayment, setTypePayment] = useState({
    webPay: false,
    khipu: false,
    payu: false,
    luka: false,
  });
  const chile = 19;
  const paymentMethods = global.paymentMethods.split(',');

  useEffect(() => {
    console.log('renderizado paymethods');
    //console.log(global);
    Object.entries(typePayment).forEach(([key, value]) => {
      if (value) {
        //console.log(key);
        props.setSelectedPay(key.toString().toUpperCase());
      }
    });
    if (typePayment && typePayment.payu) {
      document.getElementById(
        'paid_form_PayU'
      ).action = `${URL_BASE}/Pages/FrameWebPaidPayUSkin.aspx`;
      document.getElementById('SkinID').value = global.skinId;
      document.getElementById('ConfiguracionISO').value = selectedOption.Iso;
      document.getElementById('CurrencySimbol').value =
        selectedOption.MonedaSimbolo;
      document.getElementById('DetalleTID').value = detalleRegistro.Id;
      document.getElementById('Evento').value = selectedOption.Nombre;
      document.getElementById('ShowCheckTerms').value =
        global.CheckTeminosCondiciones;
      document.getElementById('paid_form_PayU').submit();
    }
  }, [typePayment]);
  useEffect(() => {
    const init = async () => {
      // if (moneda !== "CLP") {
        //let comercioSelectedId=(moneda==="EUR")?(selectedOption.ComercioIdOpcion2!=0)?selectedOption.ComercioIdOpcion2:selectedOption.ComercioId:(selectedOption.ComercioIdOpcion3)?selectedOption.ComercioIdOpcion3:selectedOption.ComercioId;

        //let paisSelectedId=(moneda==="EUR")?(selectedOption.PaisIdOpcion2!=0)?selectedOption.PaisIdOpcion2:selectedOption.PaisId:(selectedOption.PaisIdOpcion3)?selectedOption.PaisIdOpcion3:selectedOption.PaisId;

        //let paisSelectedId=(moneda==="EUR")?selectedOption.PaisIdOpcion2:selectedOption.PaisIdOpcion3;
        const lukaresponse = await Services.pay.initPayLuka(
          props.comercioId,
          props.paisId
        );
        //console.log(props.comercioId,props.paisId,lukaresponse);
        if (lukaresponse.status === 200) {
          setTokenLuka({ ...lukaresponse.data });
          setTypePayment({
            webPay: false,
            khipu: false,
            payu: false,
            luka: true,
          });
        } else {
          console.log(lukaresponse.status);
        }
      // } else {
      //   setTypePayment({
      //     webPay: true,
      //     khipu: false,
      //     payu: false,
      //     luka: false,
      //   });
      //   const response = await Services.pay.getBanksKhipu();
      //   if (response.status === 200) {
      //     if (response.data.Result) {
      //       setBanks(response.data.Bancos);
      //     }
      //   } else {
      //     console.log(response.status);
      //   }
      // }
    };
    init();
  }, []);
  useEffect(() => {
    if (tokenLuka) {
      props.setTokenLuka(tokenLuka.Token);
      displayLuka();
    }
  }, [tokenLuka]);
  useEffect(() => {
    client = props.formData;
  }, [props.formData]);
  const displayLuka = async () => {
    const btnColor = getComputedStyle(document.body)
      .getPropertyValue('--c1')
      .replace('#', '');
      let methods = (global.lukaMethods.trim()!=="")?global.lukaMethods:global.paymentMethods;
      // console.log("methods: ",methods);
    try {
      
      if (tokenLuka.Token !== '') {
        window.luka.init(
          tokenLuka.Token,
          'luka-container',
          {
            monto: props.totalMount,
            numeroDecimales: global.MostrarDecimales ? 2 : 0,
            separadorMiles: global.thousandsSeparator,
            separadorDecimal: global.decimalSeparator
          },
          null, //email
          tokenLuka.Id,
          {
            moneda:moneda,
            seleccion:2,
            terminos: {
              tipo: global.CheckTeminosCondiciones ? '1' : '2',
              comportamiento: props.handleModalTerms,
            },
            idioma: global.Idioma.toLowerCase() === 'es' ? 'esp' : 'eng',
            color: btnColor.trim(),
            fuente: global.font,
            showSkeletonLoading: true,
            horizontal:false,
            fnValidacionEsAsync: true
            ? methods
                .split(',')
                .filter((x) => x.length > 0)
                .concat('paypal')
                .join()
            : methods,
          },
          callBackLukaPay,
          props.validateFromLuka,
          ()=>{
            setShowSkeleton(false)
          }
        );
      }
    } catch (error) {
      console.log(error)
    }
  };
  const callBackLukaPay = async (result, error) => {
    if (error && error.Error.length > 0) {
      console.log('error:', error, result);
    }
    if (result === null || result === undefined) {
      result = {
        Monto: props.totalMount,
        MontoUsd: props.totalMount,
        TarjetaHabiente: {
          Nombre: client.firstName,
          Apellido: client.lastName,
          NumeroTelefono: client.phoneNumber ? client.phoneNumber : '',
        },
        Moneda:
          moneda === 'VES'
            ? global.MonedaPorDefecto
            : moneda,
        TransaccionId: 0,
        TransaccionMerchantId: 0,
        Descripcion:
          error && error.Error.length > 0
            ? error.Error[0].Descripcion
            : 'Error de Luka',
        TrazaId: error.TrazaId,
        Exitoso: false,
        MedioDePago: 'Luka',
        Canal: 'API',
      };
    }
    result.AplicarConversion =
      selectedOption.Iso === 'VES' &&
      global.dolarToday &&
      global.MonedaPorDefecto === 'USD' &&
      global.CambioDeMoneda;
    const body = {
      lukaregistrodto: result,
      consumidorskin: {
        email: client.email,
        detalleregistroId: detalleRegistro.Id,
        nombre: client.firstName,
        apellido: client.lastName,
        enviarEticket: true,
        Idioma: global.Idioma.toLowerCase(),
        paisId: selectedOption.PaisId,
        skinId: global.skinId,
      },
    };
    const { status, data } = await Services.pay.singleProcessPayLuka(body);
    if (status === 200) {
      if (
        result.Exitoso &&
        data.transaccion.error === '' &&
        data.transaccion.transaccionid !== 0
      ) {
        props.history.push({
          pathname: `/donacionexitosa/${data.transaccion.transaccionid}/2`,
          state: { confirmacion: data.confirmacion,org:selectedOption },
        });
      } else {
        if (result.Exitoso) {
          console.log('transaccion fallida:  comuniquese con el administrador');
        } else {
          console.log('transaccion fallida:  ', result.Descripcion);
        }
        props.paymentFailure(true);
      }
    } else {
      console.log('error al llamar servicio', status);
    }
  };
  return (
    <>
      <section className="content">
        <div className="method-pay-mobile">
          <div className="title-g sub-title-bold color-titulos">
            <span>{t('labelPaymentMethods')}</span>
          </div>
          <div className="info-pago">
            <div className="wrap-cont">
              <div
                className="metodos clear-fl"
                style={{
                  display: 
                  // moneda!=="CLP" ? 
                  'none' 
                  // : 'block',
                }}
              >
                {moneda === "CLP" &&
                  paymentMethods.some(
                    (name) => name.toUpperCase() === 'WEBPAY'
                  ) && (
                    <article className="item">
                      <div
                        className={
                          typePayment.webPay
                            ? 'wrap item-forma-pago selected'
                            : 'wrap item-forma-pago'
                        }
                        onClick={() =>
                          setTypePayment({
                            webPay: true,
                            khipu: false,
                            payu: false,
                            luka: false,
                          })
                        }
                        data-pago="webpay"
                      >
                        <div className="wrap-logo">
                          <img
                            src="/images/pago/webpay-g.svg"
                            alt=""
                            style={{
                              display: !typePayment.webPay ? 'block' : 'none',
                            }}
                          />
                          <img
                            src="/images/pago/webpay.svg"
                            alt=""
                            style={{
                              display: typePayment.webPay ? 'block' : 'none',
                            }}
                          />
                        </div>
                      </div>
                      <p className="color-texto-contenido">
                        Webpay - Transbank
                      </p>
                      {detalleRegistro ? (
                        <Transbank
                          initPay={props.initPay}
                          detalleRegistro={detalleRegistro}
                        />
                      ) : null}
                    </article>
                  )}
                {moneda === "CLP" &&
                  paymentMethods.some(
                    (name) => name.toUpperCase() === 'KHIPU'
                  ) && (
                    <article className="item">
                      <div
                        className={
                          typePayment.khipu
                            ? 'wrap item-forma-pago selected'
                            : 'wrap item-forma-pago'
                        }
                        onClick={() =>
                          setTypePayment({
                            webPay: false,
                            khipu: true,
                            payu: false,
                            luka: false,
                          })
                        }
                        data-pago="transf"
                      >
                        <div className="wrap-logo">
                          <span
                            className={`icon-transf size40 ${
                              typePayment.khipu ? 'icon-active-color' : ''
                            }`}
                          ></span>
                        </div>
                      </div>
                      <p className="color-texto-contenido">
                        {t('labelWireTransfer')}
                      </p>
                    </article>
                  )}
                {moneda === "CLP" &&
                  paymentMethods.some(
                    (name) => name.toUpperCase() === 'PAYU'
                  ) && (
                    <article className="item">
                      <div
                        className={
                          typePayment.payu
                            ? 'wrap item-forma-pago selected'
                            : 'wrap item-forma-pago'
                        }
                        onClick={() =>
                          setTypePayment({
                            webPay: false,
                            khipu: false,
                            payu: true,
                            luka: false,
                          })
                        }
                        data-pago="tdc"
                      >
                        <div className="wrap-logo">
                          <span
                            className={`icon-tdc size40 ${
                              typePayment.payu ? 'icon-active-color' : ''
                            }`}
                          ></span>
                        </div>
                      </div>
                      <p className="color-texto-contenido">
                        Tarjetas Internacionales
                      </p>
                    </article>
                  )}
              </div>
              {
                (showSkeleton)&&<SkeletonLuka/>
              }
              <div style={showSkeleton?{display: 'block',height:0,overflow:'hidden'}:{display: 'block'}} id="luka-container"></div>
              <div
                className="info-met info-met-mobile"
                id="transf"
                style={{
                  display: typePayment.khipu ? 'block' : 'none',
                }}
              >
                <div className="wrap-m">
                  <div id="">
                    <div className="wrap-input select">
                      <label>
                        {t('labelSelectBank')}
                        <sup className="color-ppal">*</sup>
                      </label>
                      <select
                        name="bank"
                        id=""
                        onChange={(e) => props.bank(e.target.value)}
                      >
                        <option value=""></option>
                        {banks.map((b, i) => (
                          <option value={b.bank_id} key={i}>
                            {b.name}
                          </option>
                        ))}
                      </select>
                      <i className="icon-angle-d color-ppal"></i>
                    </div>
                  </div>
                </div>
                <div
                  id="khipu-chrome-status-div"
                  style={{ display: 'none' }}
                ></div>
                <div
                  id="khipu-chrome-extension-div"
                  style={{ display: 'none' }}
                ></div>
              </div>
              <div
                className="info-met info-met-mobile"
                id="tdc"
                style={{
                  display: typePayment.payu ? 'block' : 'none',
                }}
              >
                <PayU
                  //   formValid={formValid}
                  //   formData={formData}
                  //   setPaymentFailure={setPaymentFailure}
                  //   handleModalTerms={handleModalTerms}
                  //   scrollToFormPay={scrollToFormPay}
                  //   updatePayu={updatePayu}
                  //   updateAmountPayu={updateAmountPayu}
                  //   updateValidCounter={updateValidCounter}
                  //   updateEmailRegisterDetail={updateEmailRegisterDetail}
                  global={global}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="terminos"
          style={{
            display:
              // "CLP" === moneda && !typePayment.payu
                // ? 'block':
                 'none',
          }}
        >
          <div className="tooltip-term">
            <i className="icon-term"></i>
            <span>{t('labelTermsAndConditions')}</span>
          </div>
          <div className="custom-input custom-checkbox custom-checkbox-mobile terminos-condiciones">
            <div>
              {global.CheckTeminosCondiciones && (
                <input
                  type="checkbox"
                  id="checkTerms"
                  name="checkTerms"
                  checked={props.checkTerms}
                  onChange={(e) => {
                    props.handleCheck(e.target.value);
                  }}
                />
              )}
              <label htmlFor="input-term">
                {global.CheckTeminosCondiciones && `${t('agreeTheTerms')} `}
                <a
                  data-toggle="modal"
                  data-target="#modalTerminos"
                  className="color-ppal"
                  onClick={props.handleModalTerms}
                >
                  {`${t('termsAndConditions')}`}
                </a>
              </label>
            </div>
            {global.CheckTeminosCondiciones && props.errorTerms && (
              <div>
                <span className="errorText">
                  {t('labelTermsAndConditions')}
                </span>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(PayMethods);
