import React, { Component } from 'react';
import IframeComm from 'react-iframe-comm';
import { URL_BASE_2 } from '../../../../../utils/globals';

class Transbank extends Component {
  state = {
    attributes: {
      src: '',
      width: '100%',
      height: '675',
      frameBorder: 0,
    },
  };
  componentDidMount() {
    this.setState({
      ...this.state,
      attributes: {
        ...this.state.attributes,
        src: `${URL_BASE_2}/transbank/indexiframe`,
      },
    });
  }

  onReceiveMessage = (response) => {
    const { data } = response;
    if (data.InicioPagoIframe && data.token !== '') {
      document.getElementById('token_ws').value = data.token;
      document.getElementById('formtransbank').action = data.redirecttransbank;
      document.getElementById('formtransbank').submit();
    }
  };

  onReady = () => {
    console.log('onReady');
  };

  render() {
    const { attributes } = this.state;
    const { initPay } = this.props;
    const { onReceiveMessage, onReady } = this;
    if (initPay) {
      return (
        <div className="webpay-hidden-iframe">
          <IframeComm
            attributes={attributes}
            postMessageData={initPay}
            handleReady={onReady}
            handleReceiveMessage={onReceiveMessage}
            serializeMessage={false}
          />
          <form id="formtransbank" action="" method="post">
            <input type="hidden" id="token_ws" name="token_ws" value="" />
          </form>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default Transbank;
