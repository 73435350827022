import { connect } from 'react-redux';
import ConfirmationPurchase from './ConfirmationPurchase';
import { resetEvent } from '../../actions/event/index';
const mapStateToProps = (state) => ({
  selectedFunction: state.event.selectedFunction,
  global: state.global,
});

const mapDispatchToProps = (dispatch) => ({
  resetEvent: () => dispatch(resetEvent()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationPurchase);
