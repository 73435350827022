export const ADD_PRODUCTO = "ADD_PRODUCTO";
export const UPDATE_CAR = "UPDATE_CAR";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAR_CAR = "CLEAR_CAR";
export const OPEN_MODAL = "OPEN_MODAL";

const initialState = {
  productos: [],
  loading: false,
  showCar: false,
  productoActivo: null,
};
const carReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCTO: {
      return {
        ...state,
        productoActivo: null,
        productos: [
          ...action.payload.car,
          {
            ...action.payload.producto,
            cantidad: action.payload.cantidad,
            variantes: action.payload.variantes,
          },
        ],
      };
    }
    case UPDATE_CAR: {
      return {
        ...state,
        productoActivo: null,
        productos: [...action.payload],
      };
    }
    case REMOVE_ITEM: {
      return {
        ...state,
        productoActivo: null,
        productos: state.productos.filter((n) => n.id !== action.payload),
      };
    }
    case CLEAR_CAR: {
      return {
        ...state,
        productos: [],
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        productoActivo: action.payload.producto,
      };
    }
    default:
      return state;
  }
};
export default carReducer;
