import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import IframeComm from 'react-iframe-comm';
import Services from '../../../../../services/Services';

class PayU extends Component {
  state = {
    attributes: {
      src: '',
      width: '100%',
      height: '540',
      frameBorder: 0,
      id: 'iframepaidPayU',
      name: 'iframepaidPayU',
    },
    postMessageData: '',
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.updatePayu !== this.props.updatePayu &&
      this.props.updatePayu
    ) {
      const updatePayu = {
        UpdateMonto: true,
      };
      this.setState({ postMessageData: updatePayu }, () => {
        this.props.updateAmountPayu();
      });
    }
  }
  componentDidMount() {
    // this.setState({
    //   ...this.state,
    //   attributes: {
    //     ...this.state.attributes,
    //     src: `${URL_BASE}/Pages/FrameWebPaidPayU.aspx`
    //   }
    // });
  }
  onReceiveMessage = async (response) => {
    const { data } = response;
    const { formValid, formData, global } = this.props;

    if (data.Error !== undefined && data.BuyTickets !== undefined) {
      if (data.Error === '' && data.BuyTickets && formValid) {
        await this.props.updateEmailRegisterDetail();
        const initPay = {
          CorreoConsumidor: formData.email,
          CardHolderName: formData.firstName + ' ' + formData.lastName,
          ConsumidorID: 0,
          ValidateForm: true,
        };
        this.setState({ postMessageData: initPay });
      } else {
        if (data.BuyTickets) {
          this.props.updateValidCounter();
        } else if (!data.ErrorPaid && parseInt(data.TransactionID, 10) > 0) {
          Services.eticket.send(data.TransactionID, global.Idioma);
          this.props.history.push(`/compraexitosa/${data.TransactionID}/2`);
        } else {
          this.props.setPaymentFailure();
        }
      }
    } else if (
      data.ShowTerms !== undefined &&
      data.ShowTerms !== null &&
      data.ShowTerms !== '' &&
      data.ShowTerms
    ) {
      this.props.handleModalTerms();
    }
  };

  render() {
    const { attributes, postMessageData } = this.state;
    const { onReceiveMessage, onReady } = this;
    return (
      <>
        <IframeComm
          attributes={attributes}
          postMessageData={postMessageData}
          handleReady={onReady}
          handleReceiveMessage={onReceiveMessage}
          serializeMessage={false}
        />
        <form
          id="paid_form_PayU"
          method="POST"
          target="iframepaidPayU"
          action=""
        >
          <input
            type="hidden"
            id="CurrencySimbol"
            name="CurrencySimbol"
            value="$"
          />
          <input
            type="hidden"
            id="CulturePage"
            name="CulturePage"
            value="es-CL"
          />
          <input type="hidden" id="DetalleTID" name="DetalleTID" value="" />
          <input type="hidden" id="TipoPago" name="TipoPago" value="1" />
          <input type="hidden" id="Suscripcion" name="Suscripcion" value="" />
          <input type="hidden" id="Evento" name="Evento" value="" />
          <input
            type="hidden"
            id="ConfiguracionISO"
            name="ConfiguracionISO"
            value="CLP"
          />
          <input type="hidden" id="SkinID" name="SkinID" value="" />
          <input type="hidden" id="SkinNuevo" name="SkinNuevo" value="1" />
          <input
            type="hidden"
            id="ShowCheckTerms"
            name="ShowCheckTerms"
            value="true"
          />
        </form>
      </>
    );
  }
}
export default withRouter(PayU);
