import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Purchase from './Purchase';

import {
  getEvent
} from '../../../actions/event/creators';
import {
  setFunction, deleteReservation, setResumeTickets, setTotalMount
} from '../../../actions/event/index';

const mapStateToProps = state => ({
  event: state.event.event,
  eventData: state.event.eventData,
  functions: state.event.functions,
  locations: state.event.locations,
  reservationSeats: state.event.reservationSeats,
  selectedFunction: state.event.selectedFunction,
  resumeTickets: state.event.resumeTickets,
  totalMount: state.event.totalMount,
  global: state.global,
  carProds: state.car.productos
});

const mapDispatchToProps = dispatch => ({
  getEvent: (eventId, selectedFunctionId) => dispatch(getEvent(eventId, selectedFunctionId)),
  setFunction: (itemSelected) => dispatch(setFunction(itemSelected)),
  deleteReservation: (itemSelected) => dispatch(deleteReservation(itemSelected)),
  setResumeTickets: (items) => dispatch(setResumeTickets(items)),
  setTotalMount: (mount) => dispatch(setTotalMount(mount)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Purchase));
