import API from "../../services/Services";
import {
  getEventStarted,
  getEventSuccess,
  getEventError,
  getEventFunctiosStarted,
  getEventFunctionsSuccess,
  getEventFunctionsError,
} from ".";

export const getEvent = (eventId, selectedFunctionId, notSetFunct=true) => async (dispatch) => {
  try {
    if (isNaN(eventId)){
      dispatch(getEventError("There was an error in the server."));
      return
    }
    dispatch(getEventStarted());
    const { status, data } = await API.config.getDetailSkin(eventId);
    // console.log("infoooooo",data);
    if (status === 200) {
      dispatch(getEventSuccess(data));
      dispatch(getFunctionsByEvent(eventId, selectedFunctionId, notSetFunct));
    } else {
      dispatch(getEventError("There was an error in the server."));
    }
  } catch (error) {
    dispatch(getEvent(error));
  }
};

export const getFunctionsByEvent = (eventId, selectedFunctionId, notSetFunct=true) => async (
  dispatch
) => {
  try {
    dispatch(getEventFunctiosStarted());
    const { status, data } = await API.config.getEventsByFunctions(eventId);
    //console.log(data)
    if (status === 200) {
      dispatch(getEventFunctionsSuccess(data, selectedFunctionId, notSetFunct));
    } else {
      dispatch(getEventFunctionsError("There was an error in the server."));
    }
  } catch (error) {
    dispatch(getEventFunctionsError(error));
  }
};
