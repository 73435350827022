import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setOrganization } from '../../actions/donation/';
import { setGlobalConfig,startGetConfig } from '../../actions/global/';
import Donations from './Donations';

const mapStateToProps = (state) => ({
  loading: state.event.loading,
  global: state.global,
  organization: state.donation.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  setOrganization: (itemSelected) => dispatch(setOrganization(itemSelected)),
  setGlobalConfig: (config) => dispatch(setGlobalConfig(config)),
  startGetConfig: ()=>dispatch(startGetConfig()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Donations)
);
