import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import eventReducer from './eventReducer';
import globalReducer from './globalReducer';
import donationReducer from './donationReducer';
import carReducer from './carReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    event: eventReducer,
    donation: donationReducer,
    global: globalReducer,
    car: carReducer
  });

export default rootReducer;
