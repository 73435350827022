export const GET_EVENT_START = 'event/GET_EVENT_START';
export const GET_EVENT_SUCCESS = 'event/GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'event/GET_EVENT_ERROR';
export const GET_EVENT_FUNCTIONS_START = 'event/GET_EVENT_FUNCTIONS_START';
export const GET_EVENT_FUNCTIONS_SUCCESS = 'event/GET_EVENT_FUNCTIONS_SUCCESS';
export const GET_EVENT_FUNCTIONS_ERROR = 'event/GET_EVENT_FUNCTIONS_ERROR';

export const SET_EVENT_FUNCTION = 'event/SET_EVENT_FUNCTION';
export const SET_EVENT_RESERVATION = 'event/SET_EVENT_RESERVATION';
export const DELETE_EVENT_RESERVATION = 'event/DELETE_EVENT_RESERVATION';
export const SET_EVENT_RESUME_TICKETS = 'event/SET_EVENT_RESUME_TICKETS';
export const SET_EVENT_TOTAL_MOUNT = 'event/SET_EVENT_TOTAL_MOUNT';

export const CHANGE_BLOQUED_RESERVATION = 'event/CHANGE_BLOQUED_RESERVATION';
export const REFRESH_RESERVATION = 'event/REFRESH_RESERVATION';

export const RESET_EVENT = 'event/RESET_EVENT';
