import {
    startGetConfig,
    setGlobalConfig,
    errorGetConfig,
    setDolarToday,
    setIdioma,
} from ".";
import API from "../../services/Services";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const getGlobalConfig = (domain) => async (dispatch) => {
    try {
        dispatch(startGetConfig());
        const { status, data } = await API.config.getConfig(domain);
        let SESSION = sessionStorage.getItem("session")
            ? JSON.parse(sessionStorage.getItem("session"))
            : {};
        if (status === 200) {
            dispatch(setGlobalConfig({ ...data, user: SESSION }));
        } else {
            dispatch(errorGetConfig("Error in get Config."));
        }
    } catch (error) {
        dispatch(errorGetConfig("Error in get Config."));
    }
};

export const getDolarToday = () => async (dispatch) => {
    try {
        const { status, data } = await API.external.fetchDolar();
        if (status === 200) {
            dispatch(setDolarToday(data.USD.sicad2));
        } else {
            dispatch(errorGetConfig("Error in get currency."));
        }
    } catch (error) {
        if (
            window.location.hostname === "localhost" ||
            window.location.hostname ===
                "skin-vinotinto-skin-vinotinto-staging.azurewebsites.net"
        ) {
            dispatch(setDolarToday(35.3));
        } else {
            Swal.fire({
                customClass: {
                    title: "titleCode",
                },
                title: `Error in get currency`,
                text: "https://ratedolar.blob.core.windows.net/dolar/data-rate.json",
            });
            dispatch(errorGetConfig("Error in get currency."));
        }
    }
};

export const getIdioma = (idioma) => async (dispatch) => {
    try {
        dispatch(setIdioma(idioma));
    } catch (error) {
        dispatch(errorGetConfig("Error in get idioma."));
    }
};
