import React from "react";
import { useDispatch } from "react-redux";
import { REMOVE_ITEM } from "../../../../../reducers/carReducer";

const ButtonTrash = ({ id }) => {
    const dispatch = useDispatch();
    const onclick = () => {
        dispatch({ type: REMOVE_ITEM, payload: id });
    };
    return (
        <i className="icon-cerrar" onClick={onclick} onKeyDown={() => null} />
    );
};

export default ButtonTrash;
