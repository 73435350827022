import React, { useEffect } from "react";
import {
  useConfig,
  ButtonLogin,
  ModalAuth,
  ModalSelectApp,
} from "@TicketMundo/login-tm-library";
import { Switch, Route } from "react-router-dom";
import i18n from "i18next";
import moment from "moment";
import Home from "../Home";
import Tickets from "../Tickets";
import TicketsPre from "../TicketsPre";
import Donations from "../Donations";
import ConfirmationPurchase from "../ConfirmationPurchase";
import succespage from "../succespage";
import succespage2 from "../succespage2";
import succespage3 from "../succespage3";
import Confirmation from "../Donations/Confirmation";
import { Helmet } from "react-helmet";
// import "./../../assets/scss/style.scss";
import { setFavicon } from "./../../utils/globals";
import "./../../assets/css/bootstrap.css";
import "./../../assets/css/container.css";
import "./../../assets/css/index.css";
import "./../../assets/css/theme-default.css";
import "./../../assets/css/responsive.css";
import { useSelector } from "react-redux";

const App = ({ getGlobalConfig, getDolarToday }) => {
  const global = useSelector((state) => state.global);
  const { setConfig } = useConfig();
  let SESSION = 
  useEffect(() => {
    setConfig({
      COMPANY_ID: 1,
      NAME: `Ticketmundo`,
      //openDrawerDefault: window.location.pathname.includes('/show/')  ? ((sessionStorage.getItem("session") || localStorage.getItem("user-guest") ) ? false : true) : false
    });
    const domain = window.location.hostname;
    getDolarToday();
    getGlobalConfig(domain);
    // getGlobalConfig("ccam.ticketmundo.com");
    // getGlobalConfig("usa-ticketmundo-web-app-skin-emergencia.azurewebsites.net");
    // getGlobalConfig("usa-ticketmundo-web-app-banesco-test.azurewebsites.net");
    i18n.changeLanguage(global ? global.Idioma.toLowerCase() : "en");
    moment.locale(global.Idioma.toLowerCase());
  }, [global.Idioma]);

  return (
    <>
      <ModalAuth />
      <ModalSelectApp title={`Selecciona tu app`}>
        <div>Children</div>
      </ModalSelectApp>
      <Helmet>
        <link rel="stylesheet" href={global.estilos} />
        <title>{global.Identificador}</title>
        <meta
          name="facebook-domain-verification"
          content={
            global.hasOwnProperty("FacebookVerificacion")
              ? global.FacebookVerificacion
              : null
          }
        />
        <script src={process.env.REACT_APP_LUKA_URL} />
        <style>
          {/* {`
                   .mainE{
                        --font:${global.font} !important;
                    }
                `} */}
        </style>
      </Helmet>
      {global ? (
        <Switch>
          <Route path="/event/preselection/:eventId" component={TicketsPre} />
          <Route path="/event/:eventId" component={Home} />
          <Route path="/donations/:orgId" component={Donations} />
          <Route path="/festival/:orgId" component={Donations} />
          <Route path="/show/:name/:showID" component={Tickets} />
          <Route
            path="/donacionexitosa/:transaccion"
            component={Confirmation}
          />
          <Route path="/compraenproceso" component={succespage} />

          <Route path="/compraefectivo" component={succespage2} />
          <Route path="/compraparcial" component={succespage3} />
          <Route
            path="/compraexitosa/:transaccion/:skinID"
            component={ConfirmationPurchase}
          />
        </Switch>
      ) : null}
    </>
  );
};

export default App;
