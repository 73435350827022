import { connect } from "react-redux";
import TicketsPre from "./TicketsPre";
import "./Tickets.css";
import {
  setFunction,
  setReservation,
  deleteReservation,
  setResumeTickets,
  setTotalMount,
  changeBloquedToReserve,
  refreshReservation
} from "../../actions/event/index";
import { getEvent } from "../../actions/event/creators";
import { updateDolarToday } from "../../actions/global";

const mapStateToProps = (state) => ({
  event: state.event.event,
  functions: state.event.functions,
  eventData: state.event.eventData,
  selectedFunction: state.event.selectedFunction,
  reservationSeats: state.event.reservationSeats,
  resumeTickets: state.event.resumeTickets,
  global: state.global,
});

const mapDispatchToProps = (dispatch) => ({
  setFunction: (itemSelected) => {
    dispatch(updateDolarToday(itemSelected?.FactorPrecio || 1))
    dispatch(setFunction(itemSelected))
  },
  setReservation: (itemSelected) => dispatch(setReservation(itemSelected)),
  setResumeTickets: (itemSelected) => dispatch(setResumeTickets(itemSelected)),
  deleteReservation: (itemSelected) =>
    dispatch(deleteReservation(itemSelected)),
  setTotalMount: (mount) => dispatch(setTotalMount(mount)),
  getEvent: (eventId, selectedFunctionId) =>
    dispatch(getEvent(eventId, selectedFunctionId, false)),
  changeBloquedToReserve : (tickets)=> dispatch(changeBloquedToReserve(tickets)),
  refreshReservation : async (reservaciones)=> dispatch(refreshReservation(reservaciones))
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsPre);
