import {
    GET_EVENT_START,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,
    GET_EVENT_FUNCTIONS_START,
    GET_EVENT_FUNCTIONS_SUCCESS,
    GET_EVENT_FUNCTIONS_ERROR,
    SET_EVENT_FUNCTION,
    SET_EVENT_RESERVATION,
    DELETE_EVENT_RESERVATION,
    SET_EVENT_RESUME_TICKETS,
    SET_EVENT_TOTAL_MOUNT,
    RESET_EVENT,
    CHANGE_BLOQUED_RESERVATION,
    REFRESH_RESERVATION,
} from "../actions/event/types";

const initialState = {
    event: {},
    eventData: {},
    functions: [],
    locations: [],
    reservationSeats: [],
    resumeTickets: [],
    selectedFunction: null,
    message: null,
    showMessage: false,
    loading: false,
    totalMount: 0,
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_EVENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                event: action.payload.event,
            };
        }
        case GET_EVENT_ERROR: {
            return {
                ...state,
                loading: false,
                message: action.payload.message,
            };
        }
        case GET_EVENT_FUNCTIONS_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_EVENT_FUNCTIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                eventData: action.payload.eventData,
                locations: action.payload.locations,
                functions: action.payload.functions.map((f) => {
                    f.isClickleable = true;
                    return f;
                }),
                selectedFunction: action.payload.selectedFunction,
            };
        }
        case GET_EVENT_FUNCTIONS_ERROR: {
            return {
                ...state,
                loading: false,
                message: action.payload.message,
            };
        }
        case SET_EVENT_FUNCTION: {
            return {
                ...state,
                selectedFunction: action.payload.selectedFunction,
            };
        }
        case SET_EVENT_RESERVATION: {
            const now = new Date();
            let newState = [
                ...state.reservationSeats,
                {
                    ...action.payload.reservationSeats,
                    expiry: now.getTime() + 1000 * 60 * 10,
                },
            ];
            localStorage.setItem("reservationSeats", JSON.stringify(newState));
            return {
                ...state,
                reservationSeats: newState,
            };
        }
        case DELETE_EVENT_RESERVATION: {
            let newState = state.reservationSeats.filter(
                (item) =>{
                    if(action.payload.deletedReservation.ShapeId){
                        return action.payload.deletedReservation.ShapeId !== item.ShapeId
                    }
                    return action.payload.deletedReservation.Id !== item.Id

                }
            );
            localStorage.setItem("reservationSeats", JSON.stringify(newState));
            return {
                ...state,
                reservationSeats: newState,
            };
        }
        case REFRESH_RESERVATION: {
            let newState = action.payload.refreshReservation;

            return {
                ...state,
                reservationSeats: newState,
            };
        }
        case CHANGE_BLOQUED_RESERVATION: {
            let newState = state.reservationSeats.map((item) => {
                console.log(item, action.payload.changeBloquedToReserve)
                return {
                    ...item,
                    bloqueado: false,
                    Id: item.Id
                        ? item.Id
                        : action.payload.changeBloquedToReserve.find(
                              (t) =>
                                  t.PuestoId === item.PuestoId 
                          ).Id,
                };
            });
            localStorage.setItem("reservationSeats", JSON.stringify(newState));
            return {
                ...state,
                reservationSeats: newState,
            };
        }
        case SET_EVENT_RESUME_TICKETS: {
            return {
                ...state,
                resumeTickets: action.payload.resumeTickets,
            };
        }
        case SET_EVENT_TOTAL_MOUNT: {
            return {
                ...state,
                totalMount: action.payload.totalMount,
            };
        }
        case RESET_EVENT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default eventReducer;
