import { connect } from "react-redux";
import App from "./App";

import { getGlobalConfig, getDolarToday,getIdioma } from "../../actions/global/creators";

const mapStateToProps = (state) => ({
  global: state.global,
});

const mapDispatchToProps = (dispatch) => ({
  getGlobalConfig: (domain) => dispatch(getGlobalConfig(domain)),
  getDolarToday: () => dispatch(getDolarToday()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
