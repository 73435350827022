import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { currencyFormatDE, currencyFormatValue } from "../../../utils/globals";
import TimerSmall from "../../../components/TimerSmall";

class ListItems extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    let retorno = false;
    if (this.props.items !== nextProps.items) {
      const items = nextProps.items;
      const ticketsTemp = [];
      const groupByCant = (items, key) =>
        items.reduce(
          (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
          }),
          {}
        );

      const groupedItems = groupByCant(items, "TipoEntradaId");
      Object.entries(groupedItems).forEach(([key, value]) => {
        ticketsTemp.push({ ...value[0], cantidadSel: value.length });
      });
      let mount = 0;

      ticketsTemp.map((t) => {
        mount = mount + (t.Comision + t.PrecioBase) * t.cantidadSel;
      });
      this.props.setResumeTickets(ticketsTemp);
      retorno = true;
    } else {
      let arr = Object.keys(nextProps);
      arr.map((e) => {
        if (e === "toggleWrap") {
          retorno = true;
        }
      });
    }
    return retorno;
  }

  componentDidMount() {
    const { items } = this.props;

    let itemsId = [...items].map((item, index) => item.ShapeId);

    const iframe = document.getElementsByTagName("iframe")[0];
    if (iframe) {
      iframe.onload = () => {
        setTimeout(() => {
          if (document.getElementById("zoom_links")) {
            document.getElementById("zoom_links").children[2].click();
          }
        }, 1000);
      };
      if (itemsId.length > 0) {
        document.getElementsByTagName("iframe")[0].onload = () =>
          document
            .getElementsByTagName("iframe")[0]
            .contentWindow.postMessage({ PuestosSeleccionados: itemsId }, "*");
      }
    }
  }

  calculateTotal = (puestos, mostrarCargos, selectedFunc) => {
    let Total = 0;
    puestos.map((puesto) => {
      if (mostrarCargos && selectedFunc.TipoDesglose !== 2) {
        Total =
          this.props.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? Total +
                (currencyFormatValue(
                  global.dolarToday,
                  puesto.Precio,
                  global.MostrarDecimales
                ) +
                  currencyFormatValue(
                    global.dolarToday,
                    puesto.Comision,
                    global.MostrarDecimales
                  ))
              : Total + (puesto.Precio + puesto.Comision)
            : Total + (puesto.Precio + puesto.Comision);
      } else if (selectedFunc.TipoDesglose === 2) {
        Total =
          this.props.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? Total +
                currencyFormatValue(
                  global.dolarToday,
                  puesto.Precio +
                    puesto.Comision -
                    (puesto.MontoIVA + puesto.ComisionIVA),
                  global.MostrarDecimales
                )
              : Total +
                (puesto.Precio +
                  puesto.Comision -
                  (puesto.MontoIVA + puesto.ComisionIVA))
            : Total +
              (puesto.Precio +
                puesto.Comision -
                (puesto.MontoIVA + puesto.ComisionIVA));
      } else {
        Total =
          this.props.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? Total +
                currencyFormatValue(
                  global.dolarToday,
                  puesto.Precio,
                  global.MostrarDecimales
                )
              : Total + puesto.Precio
            : Total + puesto.Precio;
      }
    });
    return Total;
  };
  render() {
    const {
      selectedTickets,
      selectedFunction,
      items,
      cancelReservation,
      pay,
      toggleWrap,
      isLoadingItems,
      global,
      Iso,
      Simbolo,
      t,
    } = this.props;

    let Monto = this.calculateTotal(
      items,
      global.mostrarCargos,
      this.props.selectedFunction
    );
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    };
    let spinnerHTML = <div className="spinner-main spinner3">
      <div className="loadingio-spinner-rolling-k94er4xvtw spinner3-b">
        <div className="ldio-arie1i327iv spinner3-c">
          <div style={{border: `10px`,borderStyle: "solid",borderColor: `#ffffff`,borderTopColor: "transparent"}}></div>
        </div>
      </div>
    </div>
    return (
      <>
        <div
          className={toggleWrap ? "covert-map cover-show" : "covert-map"}
        ></div>
        <div className={toggleWrap ? "wrap-boletos show" : "wrap-boletos"}>
          <div className="boletos">
            <div className="title color-titulos"  style={{ display: `flex`,justifyContent: `space-between`}}>
              <span> {t("seats").toUpperCase()} {items.length > 0 ? `(${items.length})` : ""}</span>
              {/*
                items.length ? <TimerSmall
                  global={global}
                  redirect={
                    selectedFunction.RequiereCola
                      ? selectedFunction.UrlLanding
                      : false
                  }
              />
              :null
              */}
              
            </div>
            <div className="content">
              {isLoadingItems > 0 ? (
                <div className="no-seats show">
                  <img
                    src="/images/pago/carritoListItems.svg"
                    alt="carrito compra"
                  />
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#909090",
                    }}
                  >
                    {t("bookingSeat")}
                  </p>
                </div>
              ) : items.length >= 1 ? (
                items.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="title-t clear-fl">
                        <ul className="cart-detail">
                          <li>{t("ticketTitle").toUpperCase()}</li>

                          {global.mostrarCargos && (
                            <li>
                              <span>
                                {this.props.selectedFunction.TipoDesglose !== 2
                                  ? t("fee").toUpperCase()
                                  : " "}
                              </span>
                            </li>
                          )}

                          <li>{t("price").toUpperCase()}</li>

                          <li></li>
                        </ul>
                      </div>
                      <div className="item clear-fl">
                        <ul className="cart-detail">
                          <li>
                            {`${item.PuestoFila}-${item.PuestoNumero}`}
                            <span className="type">
                              {capitalize(item.TipoEntradaNombre)}
                            </span>
                          </li>

                          {global.mostrarCargos && (
                            <li>
                              {this.props.selectedFunction.TipoDesglose !==
                              2 && this.props.selectedFunction.TipoDesglose !==
                              7? (
                                <span>
                                  {Iso === "VES"
                                    ? global.MonedaPorDefecto === "VES"
                                      ? `${Simbolo} ${currencyFormatDE(
                                          item.Comision,
                                          this.props.global.thousandsSeparator,
                                          this.props.global.decimalSeparator,
                                          this.props.global.MostrarDecimales
                                        )}`
                                      : `$ ${currencyFormatDE(
                                          currencyFormatValue(
                                            global.dolarToday,
                                            item.Comision,
                                            global.MostrarDecimales
                                          ),
                                          this.props.global.thousandsSeparator,
                                          this.props.global.decimalSeparator,
                                          this.props.global.MostrarDecimales
                                        )}`
                                    : `${Simbolo} ${currencyFormatDE(
                                        item.Comision,
                                        this.props.global.thousandsSeparator,
                                        this.props.global.decimalSeparator,
                                        this.props.global.MostrarDecimales
                                      )}`}
                                </span>
                              ) : this.props.selectedFunction.TipoDesglose ===
                                7 ? (
                                <span>
                                  {Iso === "VES"
                                    ? global.MonedaPorDefecto === "VES"
                                      ? `${Simbolo} ${currencyFormatDE(
                                          item.Comision,
                                          this.props.global.thousandsSeparator,
                                          this.props.global.decimalSeparator,
                                          this.props.global.MostrarDecimales
                                        )}`
                                      : `$ ${currencyFormatDE(
                                          currencyFormatValue(
                                            global.dolarToday,
                                            item.Comision,
                                            global.MostrarDecimales
                                          ),
                                          this.props.global.thousandsSeparator,
                                          this.props.global.decimalSeparator,
                                          this.props.global.MostrarDecimales
                                        )}`
                                    : `${Simbolo} ${currencyFormatDE(
                                        item.Comision,
                                        this.props.global.thousandsSeparator,
                                        this.props.global.decimalSeparator,
                                        this.props.global.MostrarDecimales
                                      )}`}
                                </span>
                              ) : (
                                <span> </span>
                              )}
                            </li>
                          )}

                          <li>
                            {this.props.selectedFunction.TipoDesglose !== 2 ? (
                              <span id="classPrecioBase">
                                {Iso === "VES"
                                  ? global.MonedaPorDefecto === "VES"
                                    ? `${Simbolo}
                                      ${currencyFormatDE(
                                        item.Precio,
                                        global.thousandsSeparator,
                                        global.decimalSeparator,
                                        global.MostrarDecimales
                                      )}`
                                    : `$
                                        ${currencyFormatDE(
                                          currencyFormatValue(
                                            global.dolarToday,
                                            item.Precio,
                                            global.MostrarDecimales
                                          ),
                                          global.thousandsSeparator,
                                          global.decimalSeparator,
                                          global.MostrarDecimales
                                        )}`
                                  : `${Simbolo}
                                      ${currencyFormatDE(
                                        item.Precio,
                                        global.thousandsSeparator,
                                        global.decimalSeparator,
                                        global.MostrarDecimales
                                      )}`}
                              </span>
                            ) : (
                              <span id="classPrecioBase">
                                {Iso === "VES"
                                  ? global.MonedaPorDefecto === "VES"
                                    ? `${Simbolo}
                                    ${currencyFormatDE(
                                      item.Precio +
                                        item.Comision -
                                        (item.MontoIVA + item.ComisionIVA),
                                      global.thousandsSeparator,
                                      global.decimalSeparator,
                                      global.MostrarDecimales
                                    )}`
                                    : `$
                                      ${currencyFormatDE(
                                        currencyFormatValue(
                                          global.dolarToday,
                                          item.Precio +
                                            item.Comision -
                                            (item.MontoIVA + item.ComisionIVA),
                                          global.MostrarDecimales
                                        ),
                                        global.thousandsSeparator,
                                        global.decimalSeparator,
                                        global.MostrarDecimales
                                      )}`
                                  : `${Simbolo}
                                    ${currencyFormatDE(
                                      item.Precio +
                                        item.Comision -
                                        item.MontoIVA,
                                      global.thousandsSeparator,
                                      global.decimalSeparator,
                                      global.MostrarDecimales
                                    )}`}
                              </span>
                            )}
                          </li>

                          <li>
                            <i
                              className="icon-cerrar"
                              data-index="0"
                              onClick={() => cancelReservation(item)}
                            ></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-seats show">
                  <img src="/images/carrito_vacio.svg" alt=""></img>
                  <div>{t("cartEmpty")} </div>
                </div>
              )}
            </div>
          </div>
          <button
            className={`btn-ppal btn-skin btn-ticket-mapa ${
              items.length >= 1 ? "active" : ""
            }`}
            disabled={this.props.reserving}
            onClick={() => {
              if (items.length >= 1) {
                pay();
              }
            }}
          >
            {(isLoadingItems > 0 || this.props.reserving) ? (
              spinnerHTML
            ) : items.length >= 1 && Monto > 0 ? (
              ` ${t("buyTicketsFor")}
                  ${Iso === "VES" ? global.MonedaPorDefecto : Simbolo}
                  ${
                    this.props.Iso === "VES"
                      ? global.MonedaPorDefecto === "USD"
                        ? currencyFormatDE(
                            currencyFormatValue(
                              global.dolarToday,
                              Monto,
                              global.MostrarDecimales
                            ),
                            global.thousandsSeparator,
                            global.decimalSeparator,
                            global.MostrarDecimales
                          )
                        : currencyFormatDE(
                            Monto,
                            global.thousandsSeparator,
                            global.decimalSeparator,
                            global.MostrarDecimales
                          )
                      : currencyFormatDE(
                          Monto,
                          global.thousandsSeparator,
                          global.decimalSeparator,
                          global.MostrarDecimales
                        )
                  }
               `
            ) : (
              t("buyTickets")
            )}
          </button>
        </div>
      </>
    );
  }
}

ListItems.propTypes = {
  toggleWrap: PropTypes.bool,
};

export default withTranslation()(ListItems);
