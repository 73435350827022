import { SET_ORGANIZATION_DONATION } from '../actions/donation/types';

const initialState = {
  selectedOrganization: null,
};
const donationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_DONATION: {
      return {
        ...state,
        selectedOrganization: action.payload.selectedOrganization,
      };
    }
    default:
      return state;
  }
};
export default donationReducer;
