import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import Services from "../../../services/Services";
import { currencyFormatDE, currencyFormatValue } from "../../../utils/globals";
import { SkeletonFree } from "../../../components/Skeleton";
// import ReCAPTCHA from "react-google-recaptcha";

class FreeAssignment extends Component {
    state = {
        tickets: [],
        amountPay: 0,
        cantidadSelTotal: 0,
        fees: 0,
        loading: false,
        reserving: false,
        reservationFailure: false,
        tokenG: null,
    };
    _isMounted = false;
    fetchData = async () => {};

    // recaptchaRef = React.createRef();

    async componentDidMount() {
        const { showID } = this.props;
        try {
            if (showID && !this.props.FreeTickets) {
                const response =
                    await Services.tickets.getFreeAssignmentTickets(showID);
                if (response.status === 200) {
                    this.setState({
                        tickets: response.data.map((t) => ({
                            ...t,
                            funcionId: showID,
                            cantidadSel: 0,
                        })),
                    });
                }
            } else if (this.props.FreeTickets) {
                this.setState({
                    tickets: [...this.props.FreeTickets].map((t) => ({
                        ...t,
                        cantidadSel: 0,
                    })),
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    componentDidUpdate() {}

    addTicket = (ticket) => {
        const { tickets, amountPay } = this.state;
        const { Iso, global } = this.props;
        let ticketTemp = tickets;
        let cantT = 0;
        ticketTemp = ticketTemp.map((t) => {
            if (t.TipoEntradaId === ticket.TipoEntradaId) {
                const fee = t.EsPorcentual
                    ? this.calculateFee(t.Precio, t.Valor)
                    : t.Valor;

                this.setState({
                    amountPay:
                        Iso === "VES"
                            ? global.MonedaPorDefecto === "USD"
                                ? amountPay +
                                  currencyFormatValue(
                                      global.dolarToday,
                                      t.Precio,
                                      global.MostrarDecimales
                                  )
                                : amountPay + t.Precio
                            : amountPay + t.Precio,
                    fees:
                        Iso === "VES"
                            ? global.MonedaPorDefecto === "USD"
                                ? this.state.fees +
                                  currencyFormatValue(
                                      global.dolarToday,
                                      fee,
                                      global.MostrarDecimales
                                  )
                                : this.state.fees + fee
                            : this.state.fees + fee,
                });

                cantT = cantT + t.cantidadSel + 1;
                return { ...t, cantidadSel: t.cantidadSel + 1 };
            } else {
                cantT = cantT + t.cantidadSel;
                return { ...t };
            }
        });

        this.setState({ cantidadSelTotal: cantT });
        this.props.handleSelectTicket();
        this.setState({ tickets: ticketTemp });
    };

    removeTicket = (ticket) => {
        const tipoEntradas = this.props.ReservationSeats.filter(
            (r) => r.TipoEntradaId === ticket.TipoEntradaId
        );
        if (tipoEntradas?.length === 0) {
            return 0;
        }

        this.props.cancelReservation(tipoEntradas[0]);
    };

    calularTotal = () => {
        const { tickets } = this.state;
        const { Iso, global } = this.props;
        const conversion = Iso === "VES" && global.MonedaPorDefecto === "USD";
        let canT = 0;

        const fees = tickets.reduce((acc, curr) => {
            let fee = curr.EsPorcentual
                ? this.calculateFee(curr.Precio, curr.Valor)
                : curr.Valor;
            if (conversion) {
                fee = currencyFormatValue(
                    global.dolarToday,
                    fee,
                    global.MostrarDecimales
                );
            }
            return acc + fee * curr.cantidadSel;
        }, 0);

        const prices = tickets.reduce((acc, curr) => {
            let price = conversion
                ? currencyFormatValue(
                      global.dolarToday,
                      curr.Precio,
                      global.MostrarDecimales
                  )
                : curr.Precio;
            canT = canT + curr.cantidadSel;
            return acc + price * curr.cantidadSel;
        }, 0);
        this.setState({
            amountPay: prices,
            fees: fees,
            cantidadSelTotal: canT,
        });
        this.props.handleSelectTicket();
    };
    handleReserve = async (ticket) => {
        try {
            const { amountPay, fees } = this.state;
            const { showID, setTotalMount } = this.props;
            setTotalMount(this.calculateTotal(amountPay, fees));
            this.setState({ ...this.state, loading: true });
            // const token = await this.recaptchaRef.current.getValue();
            this.props.setLoader(1);
            this.setState({ reserving: true });
            const reservation =
                await Services.reservations.createReservationFreeAssignment(
                    showID,
                    [
                        {
                            TipoEntradaId: ticket.TipoEntradaId,
                            Cantidad: 1,
                        },
                    ],
                    ""
                );
                console.log('reservation1',reservation)
            if (reservation.status === 200) {
                if (reservation.data.length >= 1) {
                    const reservTemp = reservation.data.map((item) => {
                        console.log(ticket)
                        this.props.setReservation({
                            ...item,
                            ZonaNombre:ticket.ZonaNombre,
                            funcionId: showID,
                        });
                        // console.log(this.state.tickets.find(n=>n.TipoEntradaId === item.TipoEntradaId))
                        return {
                            ...item,
                            ...this.state.tickets.find(
                                (n) => n.TipoEntradaId === item.TipoEntradaId
                            ),
                        };
                    });
                    if (this.props.resumeTickets) {
                        this.props.setResumeTickets([
                            ...this.props.resumeTickets,
                            ...reservTemp,
                        ]);
                    } else {
                        this.props.setResumeTickets(this.state.tickets);
                    }
                    this.props.pay();
                    this.setState({
                        ...this.state,
                        loading: false,
                        reserving: false,
                    });
                    this.props.setLoader(-1);
                }
                this.props.handleSelectTicket();
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    reserving: false,
                });
                this.props.setLoader(-1);
            }
        } catch (error) {
            const response = await Services.tickets.getFreeAssignmentTickets(
                this.props.showID
            );
            if (response.status === 200) {
                let tipoEnt = this.state.tickets;
                this.setState({
                    tickets: response.data.map((t) => {
                        let cantSel = tipoEnt.find(
                            (te) => te.TipoEntradaId === t.TipoEntradaId
                        ).cantidadSel;
                        return {
                            ...t,
                            cantidadSel:
                                cantSel > t.CantidadDisponible
                                    ? t.CantidadDisponible
                                    : cantSel,
                        };
                    }),
                    reservationFailure: true,
                });
                this.calularTotal();
            }
            console.log(error);
            this.props.setLoader(-1);
            this.setState({ ...this.state, loading: false, reserving: false });
        }
    };

    calculateFee = (price, percentage) => (price * percentage) / 100;
    calculateTotal = (price, fees) => {
        const result = price + fees;

        return result;
    };
    render() {
        const { tickets, amountPay, cantidadSelTotal, fees, loading } =
            this.state;

        const { removeTicket, handleReserve, calculateFee } = this;
        const {
            cantMaxEnt,
            Simbolo,
            global: {
                mostrarCargos,
                dolarToday,
                MonedaPorDefecto,
                MostrarDecimales,
            },
            t,
            Iso,
        } = this.props;
        return (
            <Fragment>
                <section id="wrap-ppal">
                    <div id="wrap-cont">
                        <div
                            className="container-custom-tm clear-fl"
                            style={{ touchAction: "manipulation" }}
                        >
                            <div id="tabla-boletos-wrap">
                                {tickets.length > 0 ? (
                                    <table id="tabla-boletos">
                                        <thead>
                                            <tr>
                                                <th className="color-texto-contenido">
                                                    {t(
                                                        "ticketTitle"
                                                    ).toUpperCase()}
                                                </th>
                                                <th
                                                    className="color-texto-contenido"
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {t("price").toUpperCase()}
                                                </th>
                                                {mostrarCargos && (
                                                    <th
                                                        id="thead-fee"
                                                        className="color-texto-contenido"
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {t("fee").toUpperCase()}
                                                    </th>
                                                )}
                                                <th className="color-texto-contenido">
                                                    {t("qty").toUpperCase()}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tickets.length > 0
                                                ? tickets.map((tk, i) => (
                                                      <tr
                                                          id="item-1"
                                                          className={
                                                              tk.CantidadDisponible <
                                                              1
                                                                  ? "agotado"
                                                                  : ""
                                                          }
                                                          key={i}
                                                      >
                                                          <td
                                                              className="text-tabla"
                                                              style={{
                                                                  touchAction:
                                                                      "manipulation",
                                                              }}
                                                          >
                                                              {tk.SeccionNombre}
                                                              {tk.SeccionNombre ===
                                                              tk.TipoEntradaNombre ? (
                                                                  ""
                                                              ) : (
                                                                  <div className="subText">
                                                                      {
                                                                          tk.TipoEntradaNombre
                                                                      }
                                                                  </div>
                                                              )}
                                                          </td>
                                                          <td
                                                              className="text-tabla"
                                                              style={{
                                                                  touchAction:
                                                                      "manipulation",
                                                                  textAlign:
                                                                      "center",
                                                              }}
                                                          >
                                                              {Iso === "VES"
                                                                  ? MonedaPorDefecto ===
                                                                    "VES"
                                                                      ? `${Simbolo}
                              ${currencyFormatDE(
                                  tk.Precio,
                                  this.props.global.thousandsSeparator,
                                  this.props.global.decimalSeparator,
                                  this.props.global.MostrarDecimales
                              )}`
                                                                      : `$
                                ${currencyFormatDE(
                                    currencyFormatValue(
                                        dolarToday,
                                        tk.Precio,
                                        MostrarDecimales
                                    ),
                                    this.props.global.thousandsSeparator,
                                    this.props.global.decimalSeparator,
                                    this.props.global.MostrarDecimales
                                )}`
                                                                  : `${Simbolo}
                              ${currencyFormatDE(
                                  tk.Precio,
                                  this.props.global.thousandsSeparator,
                                  this.props.global.decimalSeparator,
                                  this.props.global.MostrarDecimales
                              )}`}
                                                          </td>
                                                          {mostrarCargos && (
                                                              <td
                                                                  id="thead-fee"
                                                                  className="text-tabla"
                                                              >
                                                                  {Iso === "VES"
                                                                      ? MonedaPorDefecto ===
                                                                        "VES"
                                                                          ? `${Simbolo} ${
                                                                                tk.EsPorcentual
                                                                                    ? currencyFormatDE(
                                                                                          calculateFee(
                                                                                              tk.Precio,
                                                                                              tk.Valor
                                                                                          ),
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .thousandsSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .decimalSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .MostrarDecimales
                                                                                      )
                                                                                    : currencyFormatDE(
                                                                                          tk.Valor,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .thousandsSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .decimalSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .MostrarDecimales
                                                                                      )
                                                                            }`
                                                                          : `$ ${
                                                                                tk.EsPorcentual
                                                                                    ? currencyFormatDE(
                                                                                          calculateFee(
                                                                                              currencyFormatValue(
                                                                                                  dolarToday,
                                                                                                  tk.Precio,
                                                                                                  MostrarDecimales
                                                                                              ),
                                                                                              tk.Valor
                                                                                          ),
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .thousandsSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .decimalSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .MostrarDecimales
                                                                                      )
                                                                                    : currencyFormatDE(
                                                                                          currencyFormatValue(
                                                                                              dolarToday,
                                                                                              tk.Valor,
                                                                                              MostrarDecimales
                                                                                          ),
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .thousandsSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .decimalSeparator,
                                                                                          this
                                                                                              .props
                                                                                              .global
                                                                                              .MostrarDecimales
                                                                                      )
                                                                            }`
                                                                      : `${Simbolo} ${currencyFormatDE(
                                                                            tk.EsPorcentual
                                                                                ? calculateFee(
                                                                                      tk.Precio,
                                                                                      tk.Valor
                                                                                  )
                                                                                : tk.Valor,
                                                                            this
                                                                                .props
                                                                                .global
                                                                                .thousandsSeparator,
                                                                            this
                                                                                .props
                                                                                .global
                                                                                .decimalSeparator,
                                                                            this
                                                                                .props
                                                                                .global
                                                                                .MostrarDecimales
                                                                        )}`}
                                                              </td>
                                                          )}

                                                          <td
                                                              className="text-tabla"
                                                              style={{
                                                                  touchAction:
                                                                      "manipulation",
                                                              }}
                                                          >
                                                              {tk.CantidadDisponible <
                                                              1 ? (
                                                                  <span>
                                                                      <b>
                                                                          {t(
                                                                              "soldOut"
                                                                          ).toUpperCase()}
                                                                      </b>
                                                                  </span>
                                                              ) : (
                                                                  <div className="ctrl-cant">
                                                                      <button
                                                                          className="rem-ent"
                                                                          onClick={() =>
                                                                              removeTicket(
                                                                                  tk
                                                                              )
                                                                          }
                                                                          style={
                                                                              this.props.ReservationSeats.filter(
                                                                                  (
                                                                                      r
                                                                                  ) =>
                                                                                      r.TipoEntradaId ===
                                                                                      tk.TipoEntradaId
                                                                              )
                                                                                  ?.length >
                                                                              0
                                                                                  ? {}
                                                                                  : {
                                                                                        opacity:
                                                                                            ".5",
                                                                                        pointerEvents:
                                                                                            "none",
                                                                                    }
                                                                          }
                                                                      >
                                                                          <i className="icon-menos-simple"></i>
                                                                      </button>
                                                                      <label
                                                                          style={{
                                                                              touchAction:
                                                                                  "manipulation",
                                                                          }}
                                                                      >
                                                                          {this.props.ReservationSeats.filter(
                                                                              (
                                                                                  r
                                                                              ) =>
                                                                                  r.TipoEntradaId ===
                                                                                  tk.TipoEntradaId
                                                                          )
                                                                              ?.length ||
                                                                              0}
                                                                      </label>
                                                                      <button
                                                                          className="add-ent"
                                                                          style={
                                                                              this
                                                                                  .props
                                                                                  .ReservationSeats
                                                                                  .length <
                                                                                  this
                                                                                      .props
                                                                                      .cantMaxEnt &&
                                                                              tk.cantidadSel <
                                                                                  tk.CantidadDisponible &&
                                                                              !this
                                                                                  .state
                                                                                  .reserving
                                                                                  ? {}
                                                                                  : {
                                                                                        opacity:
                                                                                            ".5",
                                                                                        pointerEvents:
                                                                                            "none",
                                                                                    }
                                                                          }
                                                                          onClick={() =>
                                                                              handleReserve(
                                                                                  tk
                                                                              )
                                                                          }
                                                                      >
                                                                          <i className="icon-mas-simple"></i>
                                                                      </button>
                                                                  </div>
                                                              )}
                                                          </td>
                                                      </tr>
                                                  ))
                                                : null}
                                        </tbody>
                                    </table>
                                ) : null}
                            </div>
                            {/* <ReCAPTCHA
                                ref={this.recaptchaRef}
                                size="invisible"
                                sitekey="6Lc5a58mAAAAAGNGrqdVcp4i3e61S41bvqZtnpTj"
                                onChange={(value) => {
                                    this.setState({ tokenG: value });
                                }}
                            /> */}
                            {/* <button
                                className={`btn-skin btn-get-tk btn-ppal ${
                                    amountPay > 0 ? "active" : ""
                                }`}
                                onClick={() => {
                                    if (amountPay > 0) {
                                        handleReserve();
                                    }
                                }}
                            >
                                {loading ? (
                                    <div className="loading inside">
                                        <div></div>
                                    </div>
                                ) : amountPay > 0 ? (
                                    ` ${
                                        this.props.FreeTickets
                                            ? "Agregar entradas por "
                                            : t("buyTicketsFor")
                                    } ${
                                        Iso === "VES"
                                            ? MonedaPorDefecto
                                            : Simbolo
                                    } ${
                                        mostrarCargos
                                            ? currencyFormatDE(
                                                  this.calculateTotal(
                                                      amountPay,
                                                      fees
                                                  ),
                                                  this.props.global
                                                      .thousandsSeparator,
                                                  this.props.global
                                                      .decimalSeparator,
                                                  this.props.global
                                                      .MostrarDecimales
                                              )
                                            : currencyFormatDE(
                                                  amountPay,
                                                  this.props.global
                                                      .thousandsSeparator,
                                                  this.props.global
                                                      .decimalSeparator,
                                                  this.props.global
                                                      .MostrarDecimales
                                              )
                                    }`
                                ) : this.props.FreeTickets ? (
                                    "Agregar Entradas"
                                ) : (
                                    t("buyTickets")
                                )}
                            </button> */}
                        </div>
                    </div>
                    <div
                        className={
                            this.state.reservationFailure
                                ? "modal fade modalAlert in"
                                : "modal fade modalAlert"
                        }
                        style={{
                            display: this.state.reservationFailure
                                ? "block"
                                : "none",
                        }}
                        id="modalFailure"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-md"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="conten-expired clear-fl">
                                    <div className="top">
                                        <i className="icon-cerrar color-b"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title color-titulos"></div>
                                        <p className="color-texto-contenido">
                                            {t("reservationError")}
                                        </p>

                                        <p className="color-texto-contenido">
                                            {t("tryAgain").toUpperCase()}
                                        </p>
                                        <a
                                            href=""
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    reservationFailure: false,
                                                });
                                            }}
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="color-ppal hover-text-ppal"
                                        >
                                            {t("labelOK").toUpperCase()}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="footer-m">
                    <div className="container-custom-tm">
                        <div className="car color-b">
                            <span>
                                {/*<img src="/images/icono.svg" alt=""></img>*/}
                                <span style={{}}>
                                    {this.state.cantidadSelTotal || 0}
                                </span>
                            </span>
                            <p>
                                {amountPay > 0 ? (
                                    <span>
                                        {mostrarCargos ? (
                                            <span>
                                                {Iso === "VES"
                                                    ? MonedaPorDefecto
                                                    : Simbolo}{" "}
                                                {currencyFormatDE(
                                                    amountPay + fees,
                                                    this.props.global
                                                        .thousandsSeparator,
                                                    this.props.global
                                                        .decimalSeparator,
                                                    this.props.global
                                                        .MostrarDecimales
                                                )}
                                                {/* <span> + {t('fee')} </span> */}
                                            </span>
                                        ) : (
                                            <span>
                                                {Iso === "VES"
                                                    ? MonedaPorDefecto
                                                    : Simbolo}{" "}
                                                {currencyFormatDE(
                                                    amountPay,
                                                    this.props.global
                                                        .thousandsSeparator,
                                                    this.props.global
                                                        .decimalSeparator,
                                                    this.props.global
                                                        .MostrarDecimales
                                                )}
                                            </span>
                                        )}
                                    </span>
                                ) : (
                                    <span>{t("tickets")}</span>
                                )}
                            </p>
                        </div>
                        <button
                            className={`btn-skin btn-get-tk btn-ppal ${
                                amountPay > 0 ? "active" : ""
                            }`}
                            onClick={() => {
                                if (amountPay > 0) {
                                    handleReserve();
                                }
                            }}
                        >
                            {loading ? (
                                <div className="loading inside">
                                    <div></div>
                                </div>
                            ) : (
                                t("continue")
                            )}
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withTranslation()(FreeAssignment);
