import React,{useEffect,useState} from 'react';
import img from '../images/confirm.svg'
import {
  currencyFormatDE,
} from '../utils/globals';
import moment from 'moment';
import Footer from "../components/Footer";
const succesPage = ({location})=>{
    console.log("llegue",location.state)
    localStorage.removeItem("reservationSeats")
    console.log(location.state)
    let zonasagrupadas = [];
    if(location.state.reservationSeats){
        let zonas = location.state.reservationSeats.reduce((result, current) => {
        
            let zonalabel = current['SeccionNombre']? current['SeccionNombre'] : current['TipoEntradaNombre'];
            (result[zonalabel] = result[zonalabel] || []).push(current);
            return result;
        }, {});
    
        
        Object.keys(zonas).forEach((key) => {
            zonasagrupadas.push({ nombre: key, items: zonas[key] });
        });
    }
    
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  return(
    <>
        <section className='sp_bg'>
            <div className="payment_succes_header">
                <a href={location?.state?.back? location?.state?.back: "#"}>
                    <img src="https://ticketmundoblob.blob.core.windows.net/skin/logo_20.jpeg" width="249" alt="" />
                </a>
                <img src="https://cdn.ticketmundo.live/commons/pendiente-tm.gif" width="56" alt="pending" style={{padding: `12px 0`,minHeight:`80px`}}/>
                {/*<img src="https://cdn.ticketmundo.live/commons/pending.gif" width="56" alt="pending" style={{margin: `14px 0 0`}}/>*/}
                <div className='text_header'>
                    <span className="title-h" >{location.state.Nombre}, tu pago se está verificando</span>
                    <span style={{fontSize:`15px`}}> Realizamos una reserva de tu orden</span>
                    <span style={{fontSize: '15px',fontWeight: '400'}}> se enviaron detalles al correo</span>
                    <span>{location.state.Email}</span>
                </div>
            </div>
            <section className="container-custom-tm clear-fl wrap-pago wrap_succes_payment ajust_flex2">
                <div className='resumen'>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Detalle de los tickets</span>
                    </div>
                    <div className='white_cont'>
                        <img src={`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${location.state.selectedFunction.EventoId}_517_262.jpeg`} alt="" className='imag_pay'/>
                        <div className="title-g sub-title-bold color-titulos">
                            <span style={{whiteSpace:`pre-wrap`}}>{location.state.selectedFunction.EventoNombre.toLowerCase()}</span>
                            <p style={{fontWeight: '400',fontSize: '15px',lineHeight: '20px'}}>{moment.utc(location.state.selectedFunction.Fecha).format("dddd DD MMMM")} {moment.utc(location.state.selectedFunction.Fecha).format(location.state.global.formatoFecha ==="12hr"? "hh:mm": "HH:mm") +" hrs"}</p>
                            <p style={{fontWeight: '400',fontSize: '15px',lineHeight: '20px'}}>{location.state.selectedFunction.RecintoNombre} </p>
                        </div>
                        <div className="title title-bold-18">Entradas</div>
                        <div className="dir">
                            <span className="size14 fontregular">
                                {zonasagrupadas && zonasagrupadas.map((zona, index) => {
                                    return (
                                    <div key={index}>
                                        {`(${zona.items.length}) ${zona.nombre}`}
                                        {zona.items
                                        .map((item) => {
                                            let fila = item.PuestoFila !=='-'? `:${item.PuestoFila}`:'';
                                            let puesto = item.PuestoNumero !=='-'? `-${item.PuestoNumero}`:'';
                                            return `${fila}${puesto} `;
                                        })}
                                    </div>
                                    );
                                })}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Puntos Autorizados de pago</span>
                    </div>
                    <div className='white_cont'>
                        <h3 className='title_pay'>{location.state.selectedFunction.Ciudad}</h3>
                        Horarios de atención:
                        <ul className='ul_tickets'>
                            <li>
                                <label>Taquilla Ticketmundo CCCT</label>
                                LUN - SAB 09:00 AM - 05:00 PM
                            </li>
                            {/*
                            <li>
                                <label>Taquilla Ticketmundo CCCT</label>
                                LUN - VIE 09:00 AM - 06:00 PM SAB 10:00 AM - 8:00PM
                            </li>
                            <li>
                                <label>Taquilla Ticketmundo CCCT</label>
                                LUN - VIE 09:00 AM - 06:00 PM SAB 10:00 AM - 8:00PM
                            </li> */}
                        </ul>
                    </div>
                    <div className='white_cont' style={{display: 'flex',alignItems: 'center',gap: '10px',marginTop: '30px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32" fill="none">
                            <rect x="1" y="4.18262" width="32.1977" height="26.3059" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <line x1="1.89453" y1="10.2681" x2="34.0002" y2="10.2681" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <line x1="8.22266" x2="8.22266" y2="7.39886" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <line x1="27.3828" x2="27.3828" y2="7.39886" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <rect x="8.15625" y="14.3101" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="22.4648" y="14.3101" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="15.3125" y="14.3101" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="8.15625" y="21.4648" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="22.4648" y="21.4648" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="15.3125" y="21.4648" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                        </svg>
                        <div>
                            Tu reserva vence el <b>{`${moment.utc(location.state.FechaFinReserva).format("dddd DD MMMM")} 
                            ${ location.state.global.formatoFecha ==="12hr" ? ` ${moment(location.state.FechaFinReserva).format("hh:mm a")}` : ` ${moment(location.state.FechaFinReserva).format("HH:mm")} Hrs`}`}</b>
                        </div>
                    </div>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Indicaciones</span>
                    </div>
                    <div className='white_cont' style={{display: 'flex',width: '100%',marginBottom:'30px'}}>
                        <ul className='ul_'>
                            <li>Presenta el codigo de reserva enviado a tu correo electrónico en los puntos autorizados de pago.</li>
                            <li>Los billetes deben estar en buen estado , no se aceptaran billetes rotos o marcados.</li>
                            <li>No es necesario imprimir la reserva</li>
                            <li>Debes llevar el monto completo en Dolares. ( vuelto se paga en pago movil montos menores de $15.00 )</li>
                            <li>No se aceptan pagos parciales</li>  
                            <li>Una vez confirmado el pago recibiran en los proximos 15 minutos el e-ticket</li>
                            <li>Cualquier duda, sugerenca o reclamo escribanos <a href='https://wa.me/+584124286237' target="_blank" className='linkes'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#25D366" height="19px" width="19px" version="1.1" viewBox="0 0 308 308" >
                                    <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                    <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                </svg> +58 (0412) 4286237
                            </a> y al correo electrónico <a href='mailto:atencionalcliente@ticketmundo.com' target="_blank" className='linkes'>atencionalcliente@ticketmundo.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </section>
        <Footer />
    </>
  )
}

export default succesPage;