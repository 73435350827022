import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const SkeletonFree = () => {
  return (
    <>
      <div id="tabla-boletos-wrap">
        <div style={{ maxWidth: '860px', width: '100%', margin: '0 auto' }}>
          <div style={{ marginBottom: '30px' }}>
            <Skeleton height={80} />
          </div>
          <div style={{ marginBottom: '30px' }}>
            <Skeleton height={80} />
          </div>
        </div>
      </div>
    </>
  );
};

export const SkeletonHome = () => {
  return (
    <div className={`skel-background`}>
      <section className="mainE">
        <div className="container-custom-tm">
          <div className="skel-header skel-main">
            <Skeleton height={80} />
          </div>
          <div className="wrap-evento clear-fl skel-main">
            <div className="row">
              <div className="col-xs-12 col-lg-9">
                <div className="skel-img">
                  <Skeleton height={310} />
                </div>
              </div>
              <div className="col-xs-12 col-lg-3 skel-pad">
                <div className="col-xs-9">
                  <Skeleton />
                  <Skeleton width={100} />
                </div>
                <div className="col-xs-3">
                  <div className="skel-flex">
                    <Skeleton width={40} />
                  </div>
                  <div className="skel-flex2">
                    <Skeleton width={80} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row skel-row">
              <Skeleton width={300} />
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-9 col-skel">
                <div className="row skel-row2">
                  <Skeleton width={130} />
                  <Skeleton count={2} />
                  <Skeleton width={300} />
                </div>
                <div className="row skel-row2">
                  <Skeleton count={2} />
                  <Skeleton width={300} />
                </div>
                <div className="row skel-row2">
                  <Skeleton count={2} />
                  <Skeleton width={300} />
                </div>
              </div>
              <div className="col-xs-12 col-lg-3 col-skel col2s">
                <div className="row skel-row2">
                  <div>
                    <Skeleton width={130} />
                  </div>
                  <div>
                    <Skeleton width={300} />
                  </div>
                  <div className="box-skel">
                    <Skeleton height={241} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export const SkeletonConfirmation = () => {
  return (
    <div className={`skel-background`}>
      <section className="mainE">
        <div className="container-custom-tm">
          <div className="skel-header skel-main">
            <Skeleton height={40} />
          </div>
          <div className="wrap-evento clear-fl skel-main">
            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <div className="skel-img">
                  <Skeleton height={580} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
