import {
    GET_GLOBAL_CONFIG_SUCCESS,
    GET_GLOBAL_CONFIG_ERROR,
    GET_GLOBAL_CONFIG_START,
    SET_DOLAR_VALUE,
    SET_DOLAR_UPDATE,
    SET_IDIOMA_VALUE,
    SET_CURRENCY_VALUE,
    SET_LOGIN,
} from "../actions/global/types";

const initialState = {
    addressLine1: "",
    addressLine2: "",
    appTitle: "",
    BannerImagen: "",
    BannerUrl: "",
    BannerDonacionPequeno: "",
    BannerDonacionGrande: "",
    CantidadCalendarioMin: 1,
    Canalid: null,
    CheckTeminosCondiciones: "",
    decimalSeparator: ",",
    domain: "",
    isLoaded: 0,
    facebookPixelId: "",
    facebookUrl: "",
    favIcon: "",
    font: "Roboto",
    Idioma: "es",
    CambioDeMoneda: false,
    MonedaPorDefecto: "",
    instagramUrl: "",
    header: "none",
    hourFormat: "HH:mm",
    hourUnit: "hrs",
    loading: false,
    logoRedirect: "/",
    logo: "",
    logoFooter: "",
    lukaMethods: "",
    mostrarCargos: false,
    MostrarDecimales: false,
    paymentMethods: "",
    skinId: 1,
    userSession: {},
    TextoTeminosCondiciones: "",
    thousandsSeparator: ".",
    estilos: "",
    error: "",
    dolarToday: null,
    dolarUpdate: null,
    factorPrecio: 1,
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GLOBAL_CONFIG_START: {
            document.body.classList.add("modal-open");
            return {
                ...state,
                loading: action.payload,
            };
        }
        case GET_GLOBAL_CONFIG_SUCCESS: {
            document.body.classList.remove("modal-open");
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        }
        case GET_GLOBAL_CONFIG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case SET_DOLAR_VALUE: {
            return {
                ...state,
                dolarToday: action.payload * state.factorPrecio,
                dolarUpdate: action.payload,
            };
        }
        case SET_DOLAR_UPDATE: {
            return {
                ...state,
                factorPrecio: action.payload,
                dolarToday: action.payload * state.dolarToday,
            };
        }
        case SET_IDIOMA_VALUE: {
            return {
                ...state,
                Idioma: action.payload,
            };
        }
        case SET_CURRENCY_VALUE: {
            return {
                ...state,
                MonedaPorDefecto: action.payload,
            };
        }
        case SET_LOGIN: {
            return {
                ...state,
                userSession: action.payload,
            };
        }
        default:
            return state;
    }
};

export default globalReducer;
